import { Box, Button, Typography } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { DateTime } from "luxon";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import luxon3Plugin from "@fullcalendar/luxon3";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Flexbox } from "../../styling/NewStyleComponents";
import "./ScheduleToday.css";
import { blue, error, gray, success, warning } from "../../styling/colors";
import { ChevronLeft, ChevronRight, InfoOutlined } from "@mui/icons-material";
import { CustomTooltip, TurqoiseButton } from "../../styling/StyleComponents";
import CalendarEventResponseType from "common/types/Calendaring/CalendarEventResponseType";
import replace from "lodash.replace";
import { Link, useNavigate } from "react-router-dom";
import {
  CAREFLOW_PATH,
  MEMBERS_OVERVIEW_PATH
} from "../../routes/RouteComponents";
import { RootState, useAppDispatch } from "common/redux";
import { callPatientModalSelector, getTextWidth } from "../../helpers/helpers";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import styled from "@emotion/styled";
import ErrorComponent from "../ErrorComponent";
import { useCreateVisitMutation } from "common/services/VisitsService";
import VisitMotivationTypesEnum from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import {
  formatName,
  getStatusOrDispositionFromMultipleVisits
} from "common/helpers/helpers";
import {
  minusBusinessDays,
  plusBusinessDays
} from "common/helpers/BusinessDaysHelper/BusinessDaysHelper";
import VisitDispositionEnum from "common/enums/Calendaring/Visits/VisitDispositionEnum";
import { useSelector } from "react-redux";
import { canEditDeleteTimeOff } from "common/enums/RolesEnum";
import NewAddTimeOffModal from "../../pages/AllUserList/NursesSchedules/NewAddTimeOffModal";
import { resetPtoState, setCarerTimezone } from "common/redux/PtoSlice";
import EditDeleteMenu from "../ReassignAppointments/EditDeleteMenu";
import FeatureFlags from "common/config/FeatureFlags";
import useGetCurrentVisit from "common/hooks/useGetCurrentVisit";

const StyledLink = styled(Link)`
  text-decoration: none;
`;

function calculateTextWidth(containerWidth: number, isTwoColumn: boolean) {
  return (
    // multiply container width by 0.5 if this is two columns
    (isTwoColumn ? 0.5 : 1) * (containerWidth ?? 0) -
    // width of Call Button
    64 -
    // padding on both sides
    24 -
    // padding in middle
    12
  );
}

function getEventColor(event) {
  // tbd this condition should come from merged data with visits api and check for the call outcome
  if (event.title === "Meeting 1") {
    return {
      backgroundColor: error[50],
      borderColor: error[500]
    };
  } else if (event.extendedProps.isPto) {
    return {
      backgroundColor: warning[50],
      borderColor: warning[500]
    };
  } else if (event.title === "Meeting 3") {
    return {
      backgroundColor: success[50],
      borderColor: success[500]
    };
  } else {
    return {
      backgroundColor: blue[50],
      borderColor: blue[500]
    };
  }
}

function transformDataToCalendarEvents(data, carerTimezone = "local") {
  const events = [];
  const timeslots = {};
  const ptoTimeslots = {};

  data.forEach((event) => {
    if (
      ![
        // we may need to add some extra logic around telehealth nurse setup
        AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP,
        AppointmentTypeEnum.NURSE_FOLLOWUP,
        AppointmentTypeEnum.PROVIDER_FOLLOWUP
      ].includes(event.appointment_type)
    ) {
      const startDate = DateTime.fromISO(event.startdate);
      const endDate = DateTime.fromISO(event.enddate);

      let currentDate = startDate;
      if (currentDate === endDate) {
        const timeSlotDate = currentDate.toISODate();
        if (!ptoTimeslots[timeSlotDate]) {
          ptoTimeslots[timeSlotDate] = [event];
        } else {
          ptoTimeslots[timeSlotDate].push(event);
        }
      }

      while (currentDate < endDate) {
        const timeSlotDate = currentDate.toISODate();
        if (!ptoTimeslots[timeSlotDate]) {
          ptoTimeslots[timeSlotDate] = [event];
        } else {
          ptoTimeslots[timeSlotDate].push(event);
        }
        currentDate = currentDate.plus({ days: 1 });
      }
    }
    // get the first patient in attendees. We will need to change this if we have multiple patients per event
    const attendee = event.attendees.find(
      (attendee) => attendee.attendee_type === "PATIENT"
    );

    const startDate = DateTime.fromISO(event.startdate);
    const endDate = DateTime.fromISO(event.enddate);

    const timeSlot = `${startDate.toISO()}-${endDate.toISO()}`;

    let timeslotIndex;

    if (!timeslots[timeSlot]) {
      timeslots[timeSlot] = [event];
      timeslotIndex = 0;
    } else {
      timeslotIndex = timeslots[timeSlot].length;
      timeslots[timeSlot].push(event);
    }

    // the backend will also enforce that there are a maximum of four events per timeslot
    if (timeslotIndex > 3) {
      return;
    }
  });

  const timeSlotKeys = Object.keys(timeslots);
  timeSlotKeys.forEach((key) => {
    const eventsInSlot = timeslots[key];
    const startString = eventsInSlot[0].startdate;
    const endString = eventsInSlot[0].enddate;
    const startdate = DateTime.fromISO(eventsInSlot[0].startdate).setZone(
      carerTimezone
    );
    const enddate = DateTime.fromISO(eventsInSlot[0].enddate).setZone(
      carerTimezone
    );

    const timeSlot = `${startdate.toFormat("h:mm a")}-${enddate.toFormat(
      "h:mm a ZZZZ"
    )}`;

    const timeSlotDate = startdate.toISODate();

    if (!ptoTimeslots[timeSlotDate]) {
      const attendees = [];

      eventsInSlot.forEach((event) => {
        attendees.push({
          attendee: [...event.attendees],
          appointmentType: event.appointment_type,
          visitsRequest: {
            calendar_event_start: startString,
            calendar_event_end: endString,
            staff_id: event.staff.id,
            patient_id: event.attendees[0].attendee_id,
            // change to event_id with this ticket ENG-4654
            calendar_id: event.event_id
          },
          visits: event.visits
        });
      });

      attendees.sort((a, b) => {
        // sort alphabetically by first name
        return a?.attendee?.[0]?.first?.localeCompare(b?.attendee?.[0]?.first);
      });

      const transformedEvent = {
        title: timeSlot,
        start: startString,
        end: endString,
        extendedProps: {
          type: "appointment_block",
          timeSlot,
          attendees,
          isPto: false,
          timeSlotDate
        }
      };

      events.push(transformedEvent);
    } else {
      // add pto event

      const attendees = [];
      eventsInSlot.forEach((event) => {
        if (
          ![
            AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP,
            AppointmentTypeEnum.NURSE_FOLLOWUP,
            AppointmentTypeEnum.PROVIDER_FOLLOWUP
          ].includes(event.appointment_type)
        ) {
          attendees.push({
            attendee: [...event.attendees],
            appointmentType: event.appointment_type,
            visitsRequest: {
              calendar_event_start: startString,
              calendar_event_end: endString,
              staff_id: event.staff.id,
              patient_id: event.attendees[0].attendee_id,
              // change to event_id with this ticket ENG-4654
              calendar_id: event.event_id
            },
            visits: event.visits
          });
        }
      });

      if (attendees.length > 0) {
        const transformedEvent = {
          title: timeSlot,
          start: startString,
          end: endString,
          extendedProps: {
            type: "appointment_block",
            timeSlot,
            attendees,
            isPto: true,
            timeSlotDate
          }
        };
        events.push(transformedEvent);
      }
    }
  });
  return events;
}

function NewScheduleToday({
  calendarEventsData,
  startdate,
  enddate,
  carerTimezone,
  carerError,
  currentUserId,
  refetchCalendarVisits,
  selectedNurseId
}: Readonly<{
  selectedNurseId: string;
  calendarEventsData: CalendarEventResponseType[];
  startdate: DateTime;
  enddate: DateTime;
  carerTimezone?: string;
  carerError?: unknown;
  currentUserId: string;
  refetchCalendarVisits: () => void;
}>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const [rerender, setRerender] = useState({});
  const [events, setEvents] = useState([]);

  const [isEditTimeOffModalOpen, setIsEditTimeOffModalOpen] =
    useState<boolean>(false);
  const [isAddTimeOffModalOpen, setIsAddTimeOffModalOpen] =
    useState<boolean>(false);

  const [isCurrentDay, setIsCurrentDay] = useState<boolean>(false);
  const [currentDay, setCurrentDay] = useState<DateTime>(
    DateTime.now().startOf("day")
  );

  useEffect(() => {
    dispatch(setCarerTimezone(carerTimezone));
    return () => {
      dispatch(resetPtoState(null));
    };
  }, [carerTimezone]);

  useEffect(() => {
    if (!calendarEventsData) return;
    const transformedEvents = transformDataToCalendarEvents(
      calendarEventsData,
      carerTimezone
    );
    setEvents(transformedEvents);
  }, [calendarEventsData, carerTimezone]);

  const calendarRef = createRef();

  function prev() {
    // @ts-ignore
    const calendarApi = calendarRef.current.getApi();
    calendarApi.prev();

    const current = calendarApi.getDate();

    if (!DateTime.now().hasSame(current, "day")) {
      calendarApi.scrollToTime("08:00:00");
    }

    setRerender({});
  }

  function next() {
    // @ts-ignore
    const calendarApi = calendarRef.current.getApi();
    calendarApi.next();

    const current = calendarApi.getDate();

    if (!DateTime.now().hasSame(current, "day")) {
      calendarApi.scrollToTime("08:00:00");
    }

    setRerender({});
  }

  function today() {
    // @ts-ignore
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    setRerender({});
  }

  useEffect(() => {
    if (calendarRef?.current) {
      // @ts-ignore
      const calendarApi = calendarRef.current.getApi();
      const calendarDate = calendarApi.getDate();
      const calendarDateFormatted = DateTime.fromJSDate(calendarDate)
        .toUTC()
        .startOf("day");
      const currentDate = DateTime.now().startOf("day");
      if (calendarDateFormatted.hasSame(currentDate, "day")) {
        if (!isCurrentDay) setIsCurrentDay(true);
      } else if (isCurrentDay) setIsCurrentDay(false);

      if (!calendarDateFormatted.hasSame(currentDay, "day")) {
        setCurrentDay(calendarDateFormatted);
      }
    }
  }, [calendarRef, rerender]);

  const [
    createVisitMutation,
    {
      error: createVisitError,
      isSuccess: createVisitIsSuccess,
      isLoading: createVisitLoading,
      data: createVisitResult,
      reset: resetCreateVisit
    }
  ] = useCreateVisitMutation();

  // if visit is created with care flow, navigate to the care flow
  useEffect(() => {
    if (createVisitIsSuccess && createVisitResult.care_flow) {
      let careFlowLink = replace(
        CAREFLOW_PATH,
        ":visitId",
        createVisitResult?.visit_id
      );

      navigate(careFlowLink);
    }
  }, [createVisitIsSuccess]);

  const { data: currentVisit } = useGetCurrentVisit({
    staffId: user?.user_id
  });

  // version of the eventIndicator with 4 events all in the same calendar event
  function eventIndicator(
    arg,
    dispatch,
    currentRole,
    setIsEditTimeOffModalOpen
  ) {
    // if (arg?.event?.extendedProps?.type !== "appointment_block") {
    // }

    const now = DateTime.now();

    const eventCount = arg.event?.extendedProps?.attendees?.length;
    const eventStart = arg?.event?._instance?.range?.start;
    const isSameDay = now.hasSame(eventStart, "day");

    const containerWidth =
      // @ts-ignore
      document?.getElementsByClassName("fc-event-main")?.[0]?.offsetWidth;

    let approxTextContainerWidth = calculateTextWidth(
      containerWidth,
      eventCount > 4
    );

    if (arg?.event?.extendedProps?.isPto) {
      const text = canEditDeleteTimeOff(currentRole)
        ? // show the reason for managers
          `TIME OFF - ${formatName(
            arg?.event?.extendedProps?.attendees?.[0]?.appointmentType
          )}`
        : // show "OUT OF OFFICE" for nurses
          "OUT OF OFFICE";
      return (
        <Flexbox
          flexDirection="column"
          width="100%"
          height="95%"
          margin="2px 0"
        >
          <Flexbox
            textAlign="center"
            width="calc(100% - 20px)"
            margin="10px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1" fontWeight={600} color={"warning"}>
              {text}
            </Typography>
            {canEditDeleteTimeOff(currentRole) && (
              <EditDeleteMenu
                id={
                  arg.event.extendedProps.attendees[0].visitsRequest.calendar_id
                }
                staffId={
                  arg.event.extendedProps.attendees[0].visitsRequest.staff_id
                }
                event={arg.event?.extendedProps?.attendees?.[0]}
                setIsEditTimeOffModalOpen={setIsEditTimeOffModalOpen}
                refetchCalendarVisits={refetchCalendarVisits}
              />
            )}
          </Flexbox>
        </Flexbox>
      );
    }

    return (
      <Flexbox flexDirection="column" width="100%" height="95%" margin="2px 0">
        <Box textAlign="center" width="100%">
          <Typography variant="body1" fontWeight={600} color="text.primary">
            {arg.event.extendedProps.timeSlot}
          </Typography>
        </Box>
        <Flexbox
          flexDirection={"column"}
          justifyContent="space-between"
          height="85%"
          width="100%"
          padding="0 12px"
          rowGap="2px"
          columnGap={eventCount > 4 && "12px"}
          flexWrap="wrap"
        >
          {arg?.event?.extendedProps?.attendees?.length > 0 &&
            arg?.event?.extendedProps?.attendees?.map((item) => {
              const visitsRequest = item.visitsRequest;
              const visits = item.visits;
              const visitStatus =
                visits.length > 0
                  ? getStatusOrDispositionFromMultipleVisits(visits)
                  : null;

              const attendee = item.attendee[0];
              const memberId = attendee.attendee_id;
              const link = replace(
                MEMBERS_OVERVIEW_PATH,
                ":memberId",
                memberId
              );

              const appointmentTypeArray = item.appointmentType.split("_");
              const appointmentType =
                appointmentTypeArray[appointmentTypeArray.length - 1];

              let attendeeName = `${attendee.first} ${attendee.last}`;
              let recommendedEncounterText = "";
              // const randomCharacterCount = Math.round(Math.random() * 50);
              // let attendeeName = `${"a".repeat(randomCharacterCount)}`;
              const recommendedEncounterMinutes =
                attendee?.recommended_encounter_minutes;
              const recommendedTimeColor = "#7a2b20";

              const showRecommendedEncounterMinutes =
                recommendedEncounterMinutes &&
                visitStatus !== VisitDispositionEnum.COMPLETED &&
                visitStatus !== VisitDispositionEnum.NO_SHOW &&
                visitStatus !== VisitDispositionEnum.TN_OOO &&
                visitStatus !== VisitDispositionEnum.NO_CALL;

              if (visitStatus) {
                attendeeName = `${attendeeName} (${formatName(visitStatus)})`;
              }

              if (showRecommendedEncounterMinutes) {
                attendeeName = `${attendeeName} (`;
                recommendedEncounterText = `TARGET MINS: ${recommendedEncounterMinutes}`;
              }

              const approxTextWidth = getTextWidth(
                `${attendeeName}${showRecommendedEncounterMinutes ? recommendedEncounterText : ""})`,
                "14px Inter"
              );

              return (
                <Flexbox
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  key={visitsRequest.calendar_id}
                  width={
                    eventCount > 4
                      ? // 12px is the gap between the two columns
                        "calc(50% - 6px)"
                      : "100%"
                  }
                  // one line is 30.2px
                  maxHeight="31px"
                >
                  <Flexbox
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    alignItems="center"
                  >
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      color="text.primary"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      width="100%"
                      height="100%"
                    >
                      <StyledLink to={link} style={{ display: "block" }}>
                        <Typography
                          sx={{ cursor: "pointer" }}
                          variant="body1"
                          fontWeight="700"
                          color="primary.main"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          width="100%"
                          height="100%"
                        >
                          {/* TBD Comment this back in when we want to support telehealth nurse setup */}
                          {/* {appointmentType}:  */}
                          {attendeeName}
                          {showRecommendedEncounterMinutes && (
                            <>
                              <span style={{ color: recommendedTimeColor }}>
                                {recommendedEncounterText}
                              </span>
                              &#41;
                            </>
                          )}
                        </Typography>
                      </StyledLink>
                    </Typography>
                  </Flexbox>
                  <Flexbox gap="4px" alignItems="center">
                    {approxTextWidth > approxTextContainerWidth && (
                      <CustomTooltip
                        title={`${attendeeName}${showRecommendedEncounterMinutes ? recommendedEncounterText + ")" : ""}`}
                        placement="top-start"
                      >
                        <InfoOutlined color="primary" />
                      </CustomTooltip>
                    )}

                    <Flexbox gap="4px">
                      {FeatureFlags.CARE_FLOWS && isSameDay && (
                        <CustomTooltip
                          title={
                            currentVisit &&
                            "Start Encounter is disabled due to having an encounter in progress already"
                          }
                          placement="bottom"
                        >
                          <div>
                            <Button
                              sx={{
                                background: "white",
                                padding: "5px",
                                "&:hover": {
                                  backgroundColor: gray[25],
                                  pointer: "cursor"
                                },
                                lineHeight: 1.3
                              }}
                              variant="outlined"
                              color="primary"
                              onClick={async () => {
                                await createVisitMutation({
                                  staff_id: currentUserId,
                                  patient_id: visitsRequest.patient_id,
                                  body: {
                                    ...visitsRequest,
                                    staff_id: currentUserId,
                                    motivation_reason:
                                      VisitMotivationTypesEnum.APPOINTMENT
                                  },
                                  with_care_flow: true
                                });
                              }}
                            >
                              Start
                            </Button>
                          </div>
                        </CustomTooltip>
                      )}
                      {isSameDay && (
                        <Button
                          sx={{
                            background: "white",
                            padding: "5px",
                            "&:hover": {
                              backgroundColor: gray[25],
                              pointer: "cursor"
                            },
                            lineHeight: 1.3
                          }}
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            // TBD remove check once backend handles the check
                            if (visits.length === 0) {
                              createVisitMutation({
                                staff_id: currentUserId,
                                patient_id: visitsRequest.patient_id,
                                body: {
                                  ...visitsRequest,
                                  staff_id: currentUserId,
                                  motivation_reason:
                                    VisitMotivationTypesEnum.APPOINTMENT
                                }
                              });
                            }

                            callPatientModalSelector(dispatch, attendee);
                            navigate(link);
                          }}
                        >
                          Call
                        </Button>
                      )}
                      {/* <Button
                sx={{
                  background: "white",
                  padding: "5px",
                  "&:hover": {
                    backgroundColor: gray[25],
                    pointer: "cursor",
                  },
                }}
                variant="outlined"
                onClick={() => {
                  alert("update placeholder");
                }}
              >
                Update
              </Button> */}
                    </Flexbox>
                  </Flexbox>
                </Flexbox>
              );
            })}
        </Flexbox>
      </Flexbox>
    );
  }

  const getCarerTime = () => {
    return DateTime.now().setZone(carerTimezone);
  };
  const [carerTime, setCarerTime] = useState(getCarerTime());
  useEffect(() => {
    const interval = setInterval(() => setCarerTime(getCarerTime()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Box>
        <Flexbox
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Flexbox gap="16px" alignItems="center" mb="18px">
            <Flexbox gap="8px" alignItems="center">
              <Typography variant="h4">Schedule</Typography>
              <CustomTooltip
                backgroundColor={gray[200]}
                placement="bottom"
                title={
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    maxWidth="225px"
                  >
                    Your schedule for the selected date is shown here. Keep in
                    mind that appointments are scheduled in “windows” and there
                    are 4 members assigned to each one hour window.
                  </Typography>
                }
              >
                <InfoOutlined color="primary" />
              </CustomTooltip>
            </Flexbox>
            {events && events.length > 0 && (
              <Flexbox gap="8px">
                <Button
                  variant="outlined"
                  onClick={today}
                  disabled={isCurrentDay}
                >
                  Today
                </Button>
                <Button
                  variant="outlined"
                  onClick={prev}
                  disabled={
                    currentDay.toMillis() <= startdate.toMillis() ||
                    minusBusinessDays(currentDay, 1)
                      .startOf("day")
                      .toMillis() <= startdate.toMillis()
                  }
                >
                  <ChevronLeft />
                </Button>
                <Button
                  variant="outlined"
                  onClick={next}
                  disabled={
                    // disable the next button if clicking "next" will be greater than or equal to the enddate
                    plusBusinessDays(currentDay, 1).endOf("day").toMillis() >=
                    enddate.startOf("day").toMillis()
                  }
                >
                  <ChevronRight />
                </Button>
              </Flexbox>
            )}
            {carerTimezone && (
              <Typography variant="body1" color="text.secondary">
                Local time: {carerTime.toFormat("h:mm a ZZZZ")}
              </Typography>
            )}
            {(carerError || !carerTimezone) && (
              <Typography variant="body1" color="error">
                Error getting carer timezone
                <ErrorComponent
                  error={carerError}
                  style={{ fontSize: "14px" }}
                />
              </Typography>
            )}
          </Flexbox>
          {canEditDeleteTimeOff(currentRole) && (
            <TurqoiseButton
              sx={{
                width: "auto",
                marginBottom: "18px",
                // match the height of the other buttons
                height: "37px"
              }}
              onClick={() => {
                dispatch(setCarerTimezone(carerTimezone));
                setIsAddTimeOffModalOpen(true);
              }}
            >
              Add Time Off
            </TurqoiseButton>
          )}
        </Flexbox>
        {events && events.length > 0 && (
          <FullCalendar
            //@ts-ignore
            ref={calendarRef}
            viewClassNames={["schedule-today"]}
            plugins={[dayGridPlugin, timeGridPlugin, luxon3Plugin]}
            timeZone={carerTimezone ?? "local"}
            dayHeaderFormat={{
              weekday: "short",
              month: "numeric",
              day: "numeric",
              omitCommas: true
            }}
            headerToolbar={false}
            initialView="timeGridDay"
            editable={false}
            selectable={false}
            selectMirror={false}
            dayMaxEvents={false}
            weekends={false}
            allDaySlot={false}
            slotEventOverlap={false}
            slotDuration={{
              hours: 0.125
            }}
            slotLabelInterval={{ hours: 0.5 }}
            slotLabelFormat={{
              hour: "numeric",
              minute: "2-digit",
              omitZeroMinute: false,
              meridiem: "short"
            }}
            slotMinTime={"08:30:00"}
            slotMaxTime={"17:00:00"}
            nowIndicator={true}
            eventContent={(arg) =>
              eventIndicator(
                arg,
                dispatch,
                currentRole,
                setIsEditTimeOffModalOpen
              )
            }
            eventDidMount={(arg) => {
              const eventColor = getEventColor(arg.event);
              arg.el.style.backgroundColor = eventColor.backgroundColor;
              arg.el.style.borderColor = eventColor.borderColor;
            }}
            height={508}
            scrollTime={DateTime.local()
              .minus({ minutes: 30 })
              .toFormat("hh:mm:ss")}
            scrollTimeReset={true}
            initialEvents={events}
            // select={this.handleDateSelect}
            // eventClick={this.handleEventClick}
          />
        )}
        {events && events.length === 0 && (
          <Typography variant="body1" color="text.secondary">
            No calendar events found for the next 4 weeks.
          </Typography>
        )}
      </Box>
      <NewAddTimeOffModal
        modalKey={`editTimeOffModal${selectedNurseId}`}
        isEdit={true}
        isVisible={isEditTimeOffModalOpen}
        onRequestClose={(refetch: boolean) => {
          if (refetch) {
            refetchCalendarVisits();
          }
          setIsEditTimeOffModalOpen(false);
        }}
      />
      <NewAddTimeOffModal
        modalKey={`addTimeOffModal${selectedNurseId}`}
        selectedNurseId={selectedNurseId}
        isVisible={isAddTimeOffModalOpen}
        onRequestClose={(refetch: boolean) => {
          if (refetch) {
            refetchCalendarVisits();
          }
          setIsAddTimeOffModalOpen(false);
        }}
      />
    </>
  );
}

export default NewScheduleToday;
