import MemberDetailsHeader from "../Header/MemberDetailsHeader";
import MemberDetailsLayout from "../MemberDetailsLayout";
import { Box, Typography, styled } from "@mui/material";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { canSeeTasks } from "common/enums/RolesEnum";
import { useSelector } from "react-redux";
import Tasks from "../../Tasks/Tasks";
import { RootState } from "common/redux";
import { useEffect, useState } from "react";
import { checkIdValid } from "common/helpers/helpers";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import useSanitizedParams from "../../../hooks/useSanitizedParams";
import TaskStatusEnum from "common/enums/TaskStatusEnum";
import { DateTime } from "luxon";
import UpcomingAppointments from "./UpcomingAppointments";
import PastAppointments from "./PastAppointments";
import { DAYS_TO_LOOK_AHEAD } from "./constants";
import { getAthenaPatientUrl } from "common/helpers/EnvVarsHelper";

const MemberDetailsOverviewContainer = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const MemberDetailsAppointments = ({
  athenaRedirect = false,
  hideReadings = false
}: {
  athenaRedirect?: boolean;
  hideReadings?: boolean;
}) => {
  const params = useSanitizedParams();
  const { memberId } = params;

  const isValidId = checkIdValid(memberId);

  const { currentRole } = useSelector((state: RootState) => state.auth);

  const { data: patient } = useGetMemberWithUsernameQuery(
    {
      username: memberId,
      linked_entities: [
        MemberLinkedEntitiesEnum.NURSE,
        MemberLinkedEntitiesEnum.PROVIDER,
        MemberLinkedEntitiesEnum.PROVIDER_METADATA,
        MemberLinkedEntitiesEnum.PATIENT_ACCESS_SUMMARY
      ]
    },
    { skip: memberId === undefined || isValidId === false }
  );

  const [gotoathenaLinkOpened, setGotoathenaLinkOpened] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      athenaRedirect &&
      patient?.patient?.external_accounts?.athena &&
      !gotoathenaLinkOpened
    ) {
      setGotoathenaLinkOpened(true);
      window.open(
        getAthenaPatientUrl(patient?.patient?.external_accounts?.athena),
        "_blank"
      );
    }
  }, [patient, gotoathenaLinkOpened]);

  const now = DateTime.now().endOf("minute");
  const endDate = now.endOf("day").plus({ days: DAYS_TO_LOOK_AHEAD });

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return (
    <MemberDetailsOverviewContainer>
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="hidden"
      >
        <MemberDetailsHeader key={`${memberId}-header-orders`} />

        <MemberDetailsLayout
          key={`${memberId}-layout-orders`}
          memberId={memberId}
          hideReadings={hideReadings}
        >
          {canSeeTasks(currentRole) && (
            <Box>
              <Tasks
                outerContainerMargin="2.5% 0"
                tasksStatuses={[TaskStatusEnum.TODO]}
                componentHeader="To Do Tasks"
                showWhenNoTasks={false}
                noTasksMessage=""
                showResolveButton={true}
                showActionButtons={true}
                tooltipContent="Important and urgent tasks are shown in this module."
                tasksMarginBottom="24px"
              />
            </Box>
          )}

          <UpcomingAppointments
            memberId={memberId}
            startDate={now}
            endDate={endDate}
            isStaffDetails={false}
          />

          <PastAppointments memberId={memberId} isStaffDetails={false} />
        </MemberDetailsLayout>
      </Flexbox>
    </MemberDetailsOverviewContainer>
  );
};

export default MemberDetailsAppointments;
