import { GraphQLResolveInfo } from 'graphql';
import { apiGraphQL } from './../services/GraphQLService';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export enum ActionOutcome {
  Contacted = 'CONTACTED',
  Dismiss = 'DISMISS',
  Fixed = 'FIXED',
  LeftVoicemail = 'LEFT_VOICEMAIL',
  NoAnswer = 'NO_ANSWER'
}

export enum ActionType {
  AutoEscalated = 'AUTO_ESCALATED',
  Escalated = 'ESCALATED',
  PhoneCall = 'PHONE_CALL',
  Read = 'READ',
  Resolved = 'RESOLVED',
  Sms = 'SMS'
}

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1?: Maybe<Scalars['String']['output']>;
  street2?: Maybe<Scalars['String']['output']>;
};

export type CareFlow = {
  __typename?: 'CareFlow';
  createdAt?: Maybe<Scalars['String']['output']>;
  currentSection?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sections?: Maybe<Array<Maybe<Section>>>;
  variant?: Maybe<Scalars['String']['output']>;
  visitId?: Maybe<Scalars['ID']['output']>;
};

export enum CommunicationType {
  Phone = 'PHONE',
  Sms = 'SMS',
  Video = 'VIDEO'
}

export type Contact = {
  __typename?: 'Contact';
  isPreferred?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ContactAttempt = {
  __typename?: 'ContactAttempt';
  communicationType?: Maybe<CommunicationType>;
  contactedAt?: Maybe<Scalars['String']['output']>;
  contactedBy?: Maybe<Scalars['ID']['output']>;
  outcome?: Maybe<ContactOutcome>;
};

export type ContactInfo = {
  __typename?: 'ContactInfo';
  email?: Maybe<Contact>;
  landline?: Maybe<Contact>;
  mobile?: Maybe<Contact>;
};

export enum ContactOutcome {
  Contacted = 'CONTACTED',
  LeftVoicemail = 'LEFT_VOICEMAIL',
  LineBusy = 'LINE_BUSY',
  NoAnswer = 'NO_ANSWER'
}

export enum ContacteeType {
  Patient = 'PATIENT',
  Staff = 'STAFF'
}

export enum Disposition {
  Completed = 'COMPLETED',
  NoCall = 'NO_CALL',
  NoShow = 'NO_SHOW',
  TnOoo = 'TN_OOO'
}

export type Field = {
  __typename?: 'Field';
  componentType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fieldId?: Maybe<Scalars['String']['output']>;
  fieldType?: Maybe<FieldType>;
  includeInNotes?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  noteTemplate?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  subfields?: Maybe<Array<Maybe<FieldValue>>>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum FieldType {
  Checkbox = 'CHECKBOX',
  Component = 'COMPONENT',
  Longinput = 'LONGINPUT',
  Multi = 'MULTI',
  Multiselect = 'MULTISELECT',
  Radio = 'RADIO',
  Select = 'SELECT',
  Shortinput = 'SHORTINPUT',
  Text = 'TEXT'
}

export type FieldValue = {
  __typename?: 'FieldValue';
  fieldId?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum MotivationReason {
  Appointment = 'APPOINTMENT',
  DeviceIssue = 'DEVICE_ISSUE',
  MissedAppt = 'MISSED_APPT',
  NeedSupplies = 'NEED_SUPPLIES',
  Other = 'OTHER',
  Task = 'TASK',
  UrgentAlert = 'URGENT_ALERT'
}

export enum NotificationStatus {
  Error = 'ERROR',
  NotFound = 'NOT_FOUND',
  Sent = 'SENT'
}

export enum NotificationType {
  Sms = 'SMS',
  Ui = 'UI'
}

export type Patient = {
  __typename?: 'Patient';
  address?: Maybe<Address>;
  contactInfo?: Maybe<ContactInfo>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  deviceDelivered?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  enrolled?: Maybe<Scalars['String']['output']>;
  externalAccounts?: Maybe<Array<Maybe<KeyValue>>>;
  firstName?: Maybe<Scalars['String']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  lastModified?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  migrated?: Maybe<Scalars['Boolean']['output']>;
  patientId?: Maybe<Scalars['ID']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PatientStatus>;
  statusEffectiveDate?: Maybe<Scalars['String']['output']>;
  statusReason?: Maybe<PatientStatusReason>;
  statusUpdated?: Maybe<Scalars['String']['output']>;
  system?: Maybe<PatientSystem>;
  timezone?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type PatientPage = {
  __typename?: 'PatientPage';
  currentPage?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<Patient>>>;
  totalPages?: Maybe<Scalars['Int']['output']>;
  totalResults?: Maybe<Scalars['Int']['output']>;
};

export enum PatientStatus {
  Active = 'ACTIVE',
  AutoCanceled = 'AUTO_CANCELED',
  Canceled = 'CANCELED',
  CancellationRequested = 'CANCELLATION_REQUESTED',
  Eligible = 'ELIGIBLE',
  Enrolled = 'ENROLLED',
  Inactive = 'INACTIVE',
  Ineligible = 'INELIGIBLE',
  NeverEngaged = 'NEVER_ENGAGED',
  Pending = 'PENDING',
  Qualified = 'QUALIFIED',
  ReevaluatingPatient = 'REEVALUATING_PATIENT'
}

export enum PatientStatusReason {
  AdministrativeError = 'ADMINISTRATIVE_ERROR',
  AutoCanceled = 'AUTO_CANCELED',
  CanceledRetention = 'CANCELED_RETENTION',
  Deceased = 'DECEASED',
  DeviceDelivered = 'DEVICE_DELIVERED',
  EncountersActive = 'ENCOUNTERS_ACTIVE',
  InactiveLetter = 'INACTIVE_LETTER',
  InactiveNurse = 'INACTIVE_NURSE',
  InactivePatient = 'INACTIVE_PATIENT',
  InactiveRetention = 'INACTIVE_RETENTION',
  NeverEngaged = 'NEVER_ENGAGED',
  NurseEncountersActive = 'NURSE_ENCOUNTERS_ACTIVE',
  PatientCanceled = 'PATIENT_CANCELED',
  ProvidersEncountersActive = 'PROVIDERS_ENCOUNTERS_ACTIVE',
  ReadingsActive = 'READINGS_ACTIVE',
  ReadingsEncountersActive = 'READINGS_ENCOUNTERS_ACTIVE'
}

export enum PatientSystem {
  CopilotIq = 'CopilotIQ',
  S_100Plus = 'S_100Plus'
}

export type Query = {
  __typename?: 'Query';
  fetchPatients?: Maybe<PatientPage>;
  tasks?: Maybe<TaskPage>;
};


export type QueryFetchPatientsArgs = {
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTasksArgs = {
  filters?: InputMaybe<TaskFilterInput>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum Role {
  Admin = 'ADMIN',
  BiotelBot = 'BIOTEL_BOT',
  External = 'EXTERNAL',
  LogisticPartner = 'LOGISTIC_PARTNER',
  MdProvider = 'MD_PROVIDER',
  MemberCareSpecialist = 'MEMBER_CARE_SPECIALIST',
  None = 'NONE',
  NpnManager = 'NPN_MANAGER',
  Nps = 'NPS',
  NpsManager = 'NPS_MANAGER',
  NpNurse = 'NP_NURSE',
  NurseDirector = 'NURSE_DIRECTOR',
  NurseProvider = 'NURSE_PROVIDER',
  Patient = 'PATIENT',
  ProviderManager = 'PROVIDER_MANAGER',
  RcmAdmin = 'RCM_ADMIN',
  RetentionSpecialist = 'RETENTION_SPECIALIST',
  SalesDirector = 'SALES_DIRECTOR',
  System = 'SYSTEM',
  TechnicalSupport = 'TECHNICAL_SUPPORT',
  ThnManager = 'THN_MANAGER',
  ThNurse = 'TH_NURSE',
  WithingsBot = 'WITHINGS_BOT',
  ZoomBot = 'ZOOM_BOT'
}

export type Section = {
  __typename?: 'Section';
  actualTimeSeconds?: Maybe<Scalars['Int']['output']>;
  expectedTimeSeconds?: Maybe<Scalars['Int']['output']>;
  fields?: Maybe<Array<Maybe<Field>>>;
  includeInNotes?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sectionId?: Maybe<Scalars['String']['output']>;
  subsections?: Maybe<Array<Maybe<Section>>>;
};

export type Task = {
  __typename?: 'Task';
  actions?: Maybe<Array<Maybe<TaskAction>>>;
  assigneeIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  calendarEventId?: Maybe<Scalars['ID']['output']>;
  careFlow?: Maybe<CareFlow>;
  createdAt?: Maybe<Scalars['String']['output']>;
  detailAttributes?: Maybe<Array<Maybe<TaskDetailAttribute>>>;
  dueDate?: Maybe<Scalars['String']['output']>;
  escalationDate?: Maybe<Scalars['String']['output']>;
  eventDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  modifiedAt?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['ID']['output']>;
  notifications?: Maybe<Array<Maybe<TaskNotification>>>;
  patientId?: Maybe<Scalars['ID']['output']>;
  priority?: Maybe<TaskPriority>;
  priorityScore?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Role>;
  staffId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<TaskStatus>;
  taskCategory?: Maybe<TaskCategory>;
  teamId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<TaskType>;
  visit?: Maybe<Visit>;
};

export type TaskAction = {
  __typename?: 'TaskAction';
  actionId?: Maybe<Scalars['ID']['output']>;
  modifiedBy?: Maybe<Scalars['ID']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  occurredAt?: Maybe<Scalars['String']['output']>;
  outcome?: Maybe<ActionOutcome>;
  performedBy?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<ActionType>;
  visitId?: Maybe<Scalars['ID']['output']>;
};

export enum TaskAttribute {
  AthenaId = 'ATHENA_ID',
  BillingClaimId = 'BILLING_CLAIM_ID',
  Delayed = 'DELAYED',
  DeliveryDate = 'DELIVERY_DATE',
  DeviceType = 'DEVICE_TYPE',
  Diastolic = 'DIASTOLIC',
  EventDate = 'EVENT_DATE',
  Glucose = 'GLUCOSE',
  LastEncounterDate = 'LAST_ENCOUNTER_DATE',
  LastReadingDate = 'LAST_READING_DATE',
  Pulse = 'PULSE',
  ReadingQualifier = 'READING_QUALIFIER',
  ReadingSuperlative = 'READING_SUPERLATIVE',
  ReadingType = 'READING_TYPE',
  Systolic = 'SYSTOLIC'
}

export enum TaskCategory {
  BillingRequests = 'BILLING_REQUESTS',
  ClinicalQualityImprovement = 'CLINICAL_QUALITY_IMPROVEMENT',
  InternalCareCoordination = 'INTERNAL_CARE_COORDINATION',
  InternalQuestionsSupport = 'INTERNAL_QUESTIONS_SUPPORT',
  InternalScheduling = 'INTERNAL_SCHEDULING',
  NewMemberSetup = 'NEW_MEMBER_SETUP',
  OtherMemberSupport = 'OTHER_MEMBER_SUPPORT',
  PrimaryClinicalCare = 'PRIMARY_CLINICAL_CARE',
  ProviderRequests = 'PROVIDER_REQUESTS',
  SecondaryClinicalCare = 'SECONDARY_CLINICAL_CARE',
  TrainingCommunications = 'TRAINING_COMMUNICATIONS',
  UrgentClinicalCare = 'URGENT_CLINICAL_CARE'
}

export type TaskDetailAttribute = {
  __typename?: 'TaskDetailAttribute';
  key?: Maybe<TaskAttribute>;
  value?: Maybe<Scalars['String']['output']>;
};

export type TaskFilterInput = {
  activeTasks?: InputMaybe<Scalars['Boolean']['input']>;
  calendarEventId?: InputMaybe<Scalars['ID']['input']>;
  category?: InputMaybe<TaskCategory>;
  dueDateAfter?: InputMaybe<Scalars['String']['input']>;
  dueDateBefore?: InputMaybe<Scalars['String']['input']>;
  eventDateAfter?: InputMaybe<Scalars['String']['input']>;
  eventDateBefore?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  priorityScoreEqual?: InputMaybe<Scalars['Int']['input']>;
  priorityScoreGreater?: InputMaybe<Scalars['Int']['input']>;
  priorityScoreLess?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Role>;
  staffId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<TaskStatus>;
  taskId?: InputMaybe<Scalars['ID']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<TaskType>;
  visitId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskNotification = {
  __typename?: 'TaskNotification';
  contacteeId?: Maybe<Scalars['ID']['output']>;
  contacteeType?: Maybe<ContacteeType>;
  notificationId?: Maybe<Scalars['ID']['output']>;
  occurredAt?: Maybe<Scalars['String']['output']>;
  status?: Maybe<NotificationStatus>;
  type?: Maybe<NotificationType>;
};

export type TaskPage = {
  __typename?: 'TaskPage';
  currentPage?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<Task>>>;
  totalPages?: Maybe<Scalars['Int']['output']>;
  totalResults?: Maybe<Scalars['Int']['output']>;
};

export enum TaskPriority {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export enum TaskStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  InReview = 'IN_REVIEW',
  Queued = 'QUEUED',
  Scheduled = 'SCHEDULED',
  Todo = 'TODO'
}

export enum TaskType {
  AlertableReading = 'ALERTABLE_READING',
  BillingMissingDiagnosis = 'BILLING_MISSING_DIAGNOSIS',
  BillingMissingInsurance = 'BILLING_MISSING_INSURANCE',
  DeliveredDevice = 'DELIVERED_DEVICE',
  NewlyAssignedPatient = 'NEWLY_ASSIGNED_PATIENT',
  PatientOutreach = 'PATIENT_OUTREACH',
  PossibleInactivePatient = 'POSSIBLE_INACTIVE_PATIENT',
  ProviderScheduling = 'PROVIDER_SCHEDULING',
  RecordInUseByWorkflow = 'RECORD_IN_USE_BY_WORKFLOW',
  ScheduledAppointment = 'SCHEDULED_APPOINTMENT'
}

export type Visit = {
  __typename?: 'Visit';
  athenaAppointmentId?: Maybe<Scalars['String']['output']>;
  athenaDepartmentId?: Maybe<Scalars['String']['output']>;
  athenaEncounterId?: Maybe<Scalars['String']['output']>;
  athenaProviderId?: Maybe<Scalars['String']['output']>;
  calendarEventEnd?: Maybe<Scalars['String']['output']>;
  calendarEventStart?: Maybe<Scalars['String']['output']>;
  calendarId?: Maybe<Scalars['String']['output']>;
  complexity?: Maybe<VisitComplexity>;
  connectedAt?: Maybe<Scalars['String']['output']>;
  contactAttempts?: Maybe<Array<Maybe<ContactAttempt>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  disposition?: Maybe<Disposition>;
  encounterStartedOn?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['String']['output']>;
  modifiedBy?: Maybe<Scalars['ID']['output']>;
  motivationReason?: Maybe<MotivationReason>;
  patientId?: Maybe<Scalars['ID']['output']>;
  requestedBy?: Maybe<Scalars['ID']['output']>;
  staffId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<VisitStatus>;
  submittedBy?: Maybe<Scalars['ID']['output']>;
  submittedByRoles?: Maybe<Array<Maybe<Role>>>;
  taskId?: Maybe<Scalars['ID']['output']>;
  totalTime?: Maybe<Scalars['Int']['output']>;
  visitId?: Maybe<Scalars['ID']['output']>;
  visitType?: Maybe<VisitType>;
};

export enum VisitComplexity {
  Cpt_99202 = 'CPT_99202',
  Cpt_99203 = 'CPT_99203',
  Cpt_99204 = 'CPT_99204',
  Cpt_99205 = 'CPT_99205',
  Cpt_99212 = 'CPT_99212',
  Cpt_99213 = 'CPT_99213',
  Cpt_99214 = 'CPT_99214',
  Cpt_99215 = 'CPT_99215'
}

export enum VisitStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Started = 'STARTED',
  TimeSubmitted = 'TIME_SUBMITTED'
}

export enum VisitType {
  AsyncDeviceSetup = 'ASYNC_DEVICE_SETUP',
  AsyncRemotePatientMonitoring = 'ASYNC_REMOTE_PATIENT_MONITORING',
  ChronicDiseaseManagement = 'CHRONIC_DISEASE_MANAGEMENT',
  DeviceSetup = 'DEVICE_SETUP',
  InitialVisit = 'INITIAL_VISIT',
  NurseIntake = 'NURSE_INTAKE',
  ProviderIntake = 'PROVIDER_INTAKE',
  Reactivating = 'REACTIVATING',
  RemotePatientMonitoring = 'REMOTE_PATIENT_MONITORING'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ActionOutcome: ActionOutcome;
  ActionType: ActionType;
  Address: ResolverTypeWrapper<Address>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CareFlow: ResolverTypeWrapper<CareFlow>;
  CommunicationType: CommunicationType;
  Contact: ResolverTypeWrapper<Contact>;
  ContactAttempt: ResolverTypeWrapper<ContactAttempt>;
  ContactInfo: ResolverTypeWrapper<ContactInfo>;
  ContactOutcome: ContactOutcome;
  ContacteeType: ContacteeType;
  Disposition: Disposition;
  Field: ResolverTypeWrapper<Field>;
  FieldType: FieldType;
  FieldValue: ResolverTypeWrapper<FieldValue>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  KeyValue: ResolverTypeWrapper<KeyValue>;
  MotivationReason: MotivationReason;
  NotificationStatus: NotificationStatus;
  NotificationType: NotificationType;
  Patient: ResolverTypeWrapper<Patient>;
  PatientPage: ResolverTypeWrapper<PatientPage>;
  PatientStatus: PatientStatus;
  PatientStatusReason: PatientStatusReason;
  PatientSystem: PatientSystem;
  Query: ResolverTypeWrapper<{}>;
  Role: Role;
  Section: ResolverTypeWrapper<Section>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Task: ResolverTypeWrapper<Task>;
  TaskAction: ResolverTypeWrapper<TaskAction>;
  TaskAttribute: TaskAttribute;
  TaskCategory: TaskCategory;
  TaskDetailAttribute: ResolverTypeWrapper<TaskDetailAttribute>;
  TaskFilterInput: TaskFilterInput;
  TaskNotification: ResolverTypeWrapper<TaskNotification>;
  TaskPage: ResolverTypeWrapper<TaskPage>;
  TaskPriority: TaskPriority;
  TaskStatus: TaskStatus;
  TaskType: TaskType;
  Visit: ResolverTypeWrapper<Visit>;
  VisitComplexity: VisitComplexity;
  VisitStatus: VisitStatus;
  VisitType: VisitType;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Address: Address;
  Boolean: Scalars['Boolean']['output'];
  CareFlow: CareFlow;
  Contact: Contact;
  ContactAttempt: ContactAttempt;
  ContactInfo: ContactInfo;
  Field: Field;
  FieldValue: FieldValue;
  ID: Scalars['ID']['output'];
  Int: Scalars['Int']['output'];
  KeyValue: KeyValue;
  Patient: Patient;
  PatientPage: PatientPage;
  Query: {};
  Section: Section;
  String: Scalars['String']['output'];
  Task: Task;
  TaskAction: TaskAction;
  TaskDetailAttribute: TaskDetailAttribute;
  TaskFilterInput: TaskFilterInput;
  TaskNotification: TaskNotification;
  TaskPage: TaskPage;
  Visit: Visit;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CareFlowResolvers<ContextType = any, ParentType extends ResolversParentTypes['CareFlow'] = ResolversParentTypes['CareFlow']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currentSection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sections?: Resolver<Maybe<Array<Maybe<ResolversTypes['Section']>>>, ParentType, ContextType>;
  variant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visitId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contact'] = ResolversParentTypes['Contact']> = {
  isPreferred?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isVerified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactAttemptResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactAttempt'] = ResolversParentTypes['ContactAttempt']> = {
  communicationType?: Resolver<Maybe<ResolversTypes['CommunicationType']>, ParentType, ContextType>;
  contactedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contactedBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['ContactOutcome']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactInfo'] = ResolversParentTypes['ContactInfo']> = {
  email?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  landline?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  mobile?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['Field'] = ResolversParentTypes['Field']> = {
  componentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldType?: Resolver<Maybe<ResolversTypes['FieldType']>, ParentType, ContextType>;
  includeInNotes?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  noteTemplate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  subfields?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldValue']>>>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldValue'] = ResolversParentTypes['FieldValue']> = {
  fieldId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeyValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['KeyValue'] = ResolversParentTypes['KeyValue']> = {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientResolvers<ContextType = any, ParentType extends ResolversParentTypes['Patient'] = ResolversParentTypes['Patient']> = {
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  contactInfo?: Resolver<Maybe<ResolversTypes['ContactInfo']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  deviceDelivered?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dob?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enrolled?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalAccounts?: Resolver<Maybe<Array<Maybe<ResolversTypes['KeyValue']>>>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isTest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  migrated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  patientId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['PatientStatus']>, ParentType, ContextType>;
  statusEffectiveDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statusReason?: Resolver<Maybe<ResolversTypes['PatientStatusReason']>, ParentType, ContextType>;
  statusUpdated?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  system?: Resolver<Maybe<ResolversTypes['PatientSystem']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientPageResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientPage'] = ResolversParentTypes['PatientPage']> = {
  currentPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['Patient']>>>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalResults?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  fetchPatients?: Resolver<Maybe<ResolversTypes['PatientPage']>, ParentType, ContextType, Partial<QueryFetchPatientsArgs>>;
  tasks?: Resolver<Maybe<ResolversTypes['TaskPage']>, ParentType, ContextType, RequireFields<QueryTasksArgs, 'filters'>>;
};

export type SectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Section'] = ResolversParentTypes['Section']> = {
  actualTimeSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expectedTimeSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['Field']>>>, ParentType, ContextType>;
  includeInNotes?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sectionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subsections?: Resolver<Maybe<Array<Maybe<ResolversTypes['Section']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['Task'] = ResolversParentTypes['Task']> = {
  actions?: Resolver<Maybe<Array<Maybe<ResolversTypes['TaskAction']>>>, ParentType, ContextType>;
  assigneeIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  calendarEventId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  careFlow?: Resolver<Maybe<ResolversTypes['CareFlow']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  detailAttributes?: Resolver<Maybe<Array<Maybe<ResolversTypes['TaskDetailAttribute']>>>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  escalationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  modifiedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  notifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['TaskNotification']>>>, ParentType, ContextType>;
  patientId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['TaskPriority']>, ParentType, ContextType>;
  priorityScore?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType>;
  staffId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['TaskStatus']>, ParentType, ContextType>;
  taskCategory?: Resolver<Maybe<ResolversTypes['TaskCategory']>, ParentType, ContextType>;
  teamId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['TaskType']>, ParentType, ContextType>;
  visit?: Resolver<Maybe<ResolversTypes['Visit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskAction'] = ResolversParentTypes['TaskAction']> = {
  actionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  occurredAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['ActionOutcome']>, ParentType, ContextType>;
  performedBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ActionType']>, ParentType, ContextType>;
  visitId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskDetailAttributeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskDetailAttribute'] = ResolversParentTypes['TaskDetailAttribute']> = {
  key?: Resolver<Maybe<ResolversTypes['TaskAttribute']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskNotification'] = ResolversParentTypes['TaskNotification']> = {
  contacteeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  contacteeType?: Resolver<Maybe<ResolversTypes['ContacteeType']>, ParentType, ContextType>;
  notificationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  occurredAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['NotificationStatus']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['NotificationType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskPageResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskPage'] = ResolversParentTypes['TaskPage']> = {
  currentPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['Task']>>>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalResults?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitResolvers<ContextType = any, ParentType extends ResolversParentTypes['Visit'] = ResolversParentTypes['Visit']> = {
  athenaAppointmentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  athenaDepartmentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  athenaEncounterId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  athenaProviderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calendarEventEnd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calendarEventStart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calendarId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  complexity?: Resolver<Maybe<ResolversTypes['VisitComplexity']>, ParentType, ContextType>;
  connectedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contactAttempts?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContactAttempt']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  disposition?: Resolver<Maybe<ResolversTypes['Disposition']>, ParentType, ContextType>;
  encounterStartedOn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  motivationReason?: Resolver<Maybe<ResolversTypes['MotivationReason']>, ParentType, ContextType>;
  patientId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  requestedBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  staffId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['VisitStatus']>, ParentType, ContextType>;
  submittedBy?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  submittedByRoles?: Resolver<Maybe<Array<Maybe<ResolversTypes['Role']>>>, ParentType, ContextType>;
  taskId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  totalTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  visitId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  visitType?: Resolver<Maybe<ResolversTypes['VisitType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Address?: AddressResolvers<ContextType>;
  CareFlow?: CareFlowResolvers<ContextType>;
  Contact?: ContactResolvers<ContextType>;
  ContactAttempt?: ContactAttemptResolvers<ContextType>;
  ContactInfo?: ContactInfoResolvers<ContextType>;
  Field?: FieldResolvers<ContextType>;
  FieldValue?: FieldValueResolvers<ContextType>;
  KeyValue?: KeyValueResolvers<ContextType>;
  Patient?: PatientResolvers<ContextType>;
  PatientPage?: PatientPageResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Section?: SectionResolvers<ContextType>;
  Task?: TaskResolvers<ContextType>;
  TaskAction?: TaskActionResolvers<ContextType>;
  TaskDetailAttribute?: TaskDetailAttributeResolvers<ContextType>;
  TaskNotification?: TaskNotificationResolvers<ContextType>;
  TaskPage?: TaskPageResolvers<ContextType>;
  Visit?: VisitResolvers<ContextType>;
};



export const GetTasksDocument = `
    query getTasks($staffId: ID, $pageNumber: Int!, $pageSize: Int!, $status: TaskStatus) {
  tasks(
    pageNumber: $pageNumber
    pageSize: $pageSize
    filters: {staffId: $staffId, status: $status}
  ) {
    totalResults
    items {
      id
      createdAt
      patientId
      careFlow {
        visitId
      }
      status
      type
      eventDate
      dueDate
    }
  }
}
    `;

const injectedRtkApi = apiGraphQL.injectEndpoints({
  endpoints: (build) => ({
    getTasks: build.query<GetTasksQuery, GetTasksQueryVariables>({
      query: (variables) => ({ document: GetTasksDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetTasksQuery, useLazyGetTasksQuery } = injectedRtkApi;

