enum CareFlowFieldComponentEnum {
  GLUCOSE_READING_CHART = "GLUCOSE_READING_CHART",
  BLOOD_PRESSURE_READING_CHART = "BLOOD_PRESSURE_READING_CHART",
  PULSE_READING_CHART = "PULSE_READING_CHART",
  OXIMETER_READING_CHART = "OXIMETER_READING_CHART",
  WEIGHT_SCALE_READING_CHART = "WEIGHT_SCALE_READING_CHART",
  MISSING_AGREEMENTS_TABLE = "MISSING_AGREEMENTS_TABLE",
  ORDER_HISTORY = "ORDER_HISTORY",
  CONNECT_WITH_MEMBER = "CONNECT_WITH_MEMBER",
  MEMBER_RELATIONSHIP_NOTES = "MEMBER_RELATIONSHIP_NOTES",
  UPCOMING_APPOINTMENTS = "UPCOMING_APPOINTMENTS",
  BILLING_ENTRY_NURSE = "BILLING_ENTRY_NURSE",
  GENERATE_NOTES = "GENERATE_NOTES",
  START_ENCOUNTER = "START_ENCOUNTER",
  END_ENCOUNTER = "END_ENCOUNTER",
  QUOTE = "QUOTE",
  IN_LICENSED_STATE = "IN_LICENSED_STATE",
  ORDER_REFILLS = "ORDER_REFILLS",
  TAB = "TAB",
  TAB_GROUP = "TAB_GROUP",
  DATETIME = "DATETIME"
}

export default CareFlowFieldComponentEnum;
