import Skeleton from "react-loading-skeleton";
import styled from "@emotion/styled";
import useGetPeriodReadings from "common/hooks/useGetPeriodReadings";
import MemberType from "common/types/MemberType";
import { HelpIcon } from "../../../assets/images/icons";
import { ErrorText } from "../../../styling";
import { CustomTooltip } from "../../../styling/StyleComponents";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { HelpOutlineOutlined, WarningAmberOutlined } from "@mui/icons-material";
import EnvVars from "common/config/EnvVars";
import ComplianceBannerNew from "./ComplianceBannerNew";
import FeatureFlags from "common/config/FeatureFlags";

const OuterContainer = styled.div`
  margin: 16px 0;
`;

const ComplianceBannerContainer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.color.defaultBorder};
  border-radius: 4px;
  align-items: center;
  width: 100%;
`;

const ComplianceBannerLeft = styled.div`
  display: flex;
  align-items: center;
`;
const ComplianceBannerRight = styled.div<{
  $flexDirection?: string;
  width?: string;
}>`
  width: ${(props) => (props?.width ? props?.width : "100%")};
  text-align: right;
  display: flex;
  flex-direction: ${(props) =>
    props?.$flexDirection ? props?.$flexDirection : "column"};
  justify-content: flex-end;
`;
const ComplianceBannerTextContainer = styled.div`
  margin-left: 15px;
  width: max-content;
`;

const PDBTextHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #434656;
`;
const PDBTextSubHeading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props?.theme?.color?.veryDarkBlue};
`;

const DataContainer = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0.03em;
  color: rgba(20, 21, 25, 0.9);
`;
const DataLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  overflow-wrap: break-word;
`;

function getComplianceText(
  complianceDays: number | undefined,
  compliancePeriodError: any
) {
  if (compliancePeriodError) {
    return <ErrorText>Not applicable</ErrorText>;
  }
  const complianceText = complianceDays
    ? `${complianceDays} days remaining`
    : "";
  return complianceText;
}

interface IProps {
  patient: MemberType;
}

const ComplianceBanner = ({ patient }: IProps) => {
  // FEATURE FLAG: AUG-2024-CARE-FLOWS
  if (FeatureFlags.CARE_FLOWS) {
    return <ComplianceBannerNew patient={patient} />;
  }

  const { patient_id, timezone } = patient.patient;

  const {
    data: compliancePeriod,
    error,
    isLoading: loadingPeriod
  } = useGetPeriodReadings(patient_id, timezone);

  return (
    <OuterContainer>
      <Flexbox>
        <ComplianceBannerContainer>
          <ComplianceBannerLeft>
            <WarningAmberOutlined color="error" />
            <ComplianceBannerTextContainer>
              <PDBTextHeading>
                Device Usage{" "}
                <CustomTooltip
                  title={
                    <div>
                      Member must take at least one reading on 16 different days
                      <br />
                      during every 30-day period
                    </div>
                  }
                >
                  <HelpOutlineOutlined />
                </CustomTooltip>
              </PDBTextHeading>
              <PDBTextSubHeading>
                {loadingPeriod ? (
                  <Skeleton />
                ) : (
                  <>
                    {getComplianceText(compliancePeriod?.daysRemaining, error)}
                  </>
                )}
              </PDBTextSubHeading>
            </ComplianceBannerTextContainer>
          </ComplianceBannerLeft>
          <ComplianceBannerRight $flexDirection="column">
            {loadingPeriod ? (
              <Skeleton count={2} />
            ) : compliancePeriod === undefined ? undefined : (
              <DataContainer>
                <span
                  style={
                    compliancePeriod?.readingDays <
                    compliancePeriod?.totalReadings
                      ? { color: "#FF5364", fontSize: "34px" }
                      : { color: "green" }
                  }
                >
                  {compliancePeriod.readingDays}
                </span>
                /{compliancePeriod.totalReadings}
              </DataContainer>
            )}

            <DataLabel>
              Days with <br /> Readings
            </DataLabel>
          </ComplianceBannerRight>
        </ComplianceBannerContainer>
      </Flexbox>
    </OuterContainer>
  );
};

export default ComplianceBanner;
