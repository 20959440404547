import { MenuItem, TextField, Typography } from "@mui/material";
import { TurqoiseButton, WhiteButton } from "../../styling";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import VisitMotivationTypesEnum, {
  VisitMotivationTypesEnum_toString
} from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import { useEffect, useState } from "react";
import { useCreateVisitMutation } from "common/services/VisitsService";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import VisitReasonsEnum from "common/enums/Calendaring/Visits/VisitReasonsEnum";
import { useNavigate } from "react-router-dom";
import { Alert_show } from "common/helpers/AlertHelper";
import ErrorComponent from "../ErrorComponent";
import { useAppDispatch } from "common/redux";

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  memberId: string;
}

const DATA = [
  VisitMotivationTypesEnum.DEVICE_ISSUE,
  VisitMotivationTypesEnum.MISSED_APPT,
  VisitMotivationTypesEnum.URGENT_ALERT
];

const StartVisitModal = ({ isOpen, onRequestClose, memberId }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedReason, setSelectedReason] =
    useState<VisitMotivationTypesEnum>();

  const { data: staff } = useGetAuthenticatedUser();

  const [createVisitMutation, { data, isSuccess, error, isLoading }] =
    useCreateVisitMutation();

  const handleStartVisit = () => {
    createVisitMutation({
      body: {
        staff_id: staff.user.user_id,
        patient_id: memberId,
        motivation_reason: selectedReason,
        status: VisitStatusEnum.IN_PROGRESS,
        visit_type: VisitReasonsEnum.REMOTE_PATIENT_MONITORING
      },
      staff_id: staff.user.user_id,
      patient_id: memberId,
      with_care_flow: true
    });
  };

  useEffect(() => {
    if (isSuccess && data) {
      const { visit_id } = data;
      onRequestClose();
      navigate(`/visits/${visit_id}`);
    }
    if (error) {
      Alert_show({
        dispatch,
        title: "Could not create Encounter",
        // @ts-ignore
        content: <ErrorComponent error={error?.response?.data?.message} />
      });
    }
  }, [isSuccess, data, error]);

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Start Encounter Modal"
      modalHeight="500px"
      modalWidth="400px"
    >
      <ModalHeader onRequestClose={onRequestClose}>Start Encounter</ModalHeader>
      <ModalBody>
        <Typography variant="h6">
          Are you ready to begin the visit with the member?
        </Typography>
        <Typography variant="h6">Select the reason for the visit</Typography>

        <br />
        <TextField
          label={"Reason"}
          select={true}
          SelectProps={{
            variant: "outlined",
            MenuProps: { PaperProps: { sx: { maxHeight: 200 } } },
            onChange: (event) => {
              setSelectedReason(event.target.value as VisitMotivationTypesEnum);
            }
          }}
          placeholder={"Enter Reason"}
          fullWidth={true}
        >
          {DATA.map((item) => (
            <MenuItem key={item} value={item}>
              {VisitMotivationTypesEnum_toString(item)}
            </MenuItem>
          ))}
        </TextField>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          <TurqoiseButton
            loading={isLoading}
            disabled={!staff || !selectedReason}
            onClick={handleStartVisit}
          >
            Start Encounter
          </TurqoiseButton>
          <WhiteButton onClick={onRequestClose}>Cancel</WhiteButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

export default StartVisitModal;
