import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";

import {
  useCloseRoomMutation,
  useCreateRoomMutation,
  useGetMemberOngoingCallQuery
} from "common/services/VideoService";
import MemberType from "common/types/MemberType";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import { ONE_MINUTE, getNameOrUsername, isFalsy } from "common/helpers/helpers";
import { RootState, useAppDispatch } from "common/redux";
import { CURRENT_TAB_ID, joinVideoCallRoom } from "common/redux/VideoCallSlice";
import { Stop, VideocamOutlined } from "@mui/icons-material";
import { CustomTooltip } from "../../styling/StyleComponents";
import {
  PermissionsHelper_askForCamera,
  PermissionsHelper_askForMicrophone
} from "common/helpers/PermissionsHelper";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { Flexbox } from "../../styling/NewStyleComponents";
import { useSelector } from "react-redux";
import EnvVars from "common/config/EnvVars";
import FeatureFlags from "common/config/FeatureFlags"
import CallTwilioPatientButtonNew from "./CallTwilioPatientButtonNew";

// import VideoCallComponent from "../Twilio/VideoCallComponent";
// Can't lazy load in this component at the moment - there is an issue where the Layout reducer
// does not get data hydrated when lazy loading. We need the nodeRef for the draggable component. https://github.com/reduxjs/redux/discussions/4114
// const VideoCallComponent = lazy(
//   () => import("../../components/Twilio/VideoCallComponent")
// );

interface IProps {
  patient: MemberType;
}

const StopButtonStyle = {
  fontSize: "20px",
  padding: "-1px",
  borderBottomRightRadius: "0px",
  borderTopRightRadius: "0px"
};

const JoinButtonStyle = {
  fontSize: "20px"
};

const JoinButtonJoinedStyle = {
  borderBottomLeftRadius: "0px",
  borderTopLeftRadius: "0px"
};

const DurationContainerStyle = {
  display: "flex",
  width: "70px",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid gray",
  borderStyle: "solid none"
};

const CallTwillioPatientButton = ({ patient }: IProps) => {
  // FEATURE FLAG: AUG-2024-CARE-FLOWS
  if (FeatureFlags.CARE_FLOWS) {
    return <CallTwilioPatientButtonNew patient={patient} />;
  }

  const dispatch = useAppDispatch();
  const { data: user } = useGetAuthenticatedUser();

  const { code } = useSelector((state: RootState) => state.videoCall);

  const [createRoomMutation, { data: createRoomData, error, isLoading }] =
    useCreateRoomMutation();

  const [closeRoomMutation, { isLoading: closeRoomLoading }] =
    useCloseRoomMutation();

  const { data } = useGetMemberOngoingCallQuery(
    {
      member_id: patient?.patient?.patient_id
    },
    {
      skip: isFalsy(user) || isFalsy(patient),
      pollingInterval: ONE_MINUTE * 1000
    }
  );

  const identity = getNameOrUsername(user?.user);

  useEffect(() => {
    if (createRoomData?.code === undefined) return;
    const { code } = createRoomData;

    dispatch(joinVideoCallRoom({ code, tabId: CURRENT_TAB_ID }));
  }, [createRoomData, error, identity]);

  const joinRoomHandler = () => {
    if (data) {
      const { code } = data;
      dispatch(joinVideoCallRoom({ code, tabId: CURRENT_TAB_ID }));
    } else {
      PermissionsHelper_askForMicrophone()
        .then(() => {
          return PermissionsHelper_askForCamera();
        })
        .then(() => {
          createRoomMutation({
            carerId: user?.user.user_id,
            startDateTime: DateTime.now(),
            memberId: patient.patient.patient_id
          });
        })
        .catch(() => {
          Alert_show({
            dispatch,
            id: "permissions_alert",
            title: "Camera and microphone are blocked",
            content:
              "We are unable to connect to your camera or microphone. Please check your device and network settings and try again",
            size: "medium",
            buttons: [
              {
                text: "Ok",
                onPress: () =>
                  Alert_close({ dispatch, id: "permissions_alert" })
              }
            ]
          });
        });
    }
  };

  const [currentDate, setCurrentDate] = useState<DateTime>(DateTime.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(DateTime.now());
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const startedDate = DateTime.fromISO(data?.start_datetime);
  const duration = currentDate.diff(startedDate).toFormat("mm:ss");

  return (
    <CustomTooltip
      backgroundColor="#ffffff"
      placement="bottom"
      title={
        <Typography variant="body1" color="text.primary">
          Video Call
        </Typography>
      }
    >
      <Flexbox>
        {data && (
          <>
            <LoadingButton
              variant="outlined"
              aria-label="End Call"
              sx={StopButtonStyle}
              loading={closeRoomLoading}
              onClick={() => {
                closeRoomMutation({ code: data.code });
              }}
            >
              {!closeRoomLoading && (
                <Stop
                  fontSize="inherit"
                  sx={{ cursor: "pointer" }}
                  color={"primary"}
                />
              )}
            </LoadingButton>
            <div style={DurationContainerStyle}>
              <Typography variant="body1" color="text.secondary">
                {duration}
              </Typography>
            </div>
          </>
        )}
        <LoadingButton
          variant="outlined"
          loading={isLoading}
          onClick={joinRoomHandler}
          disabled={code !== null && data === null}
          aria-label="Video Call"
          sx={[JoinButtonStyle, data ? JoinButtonJoinedStyle : undefined]}
        >
          {!isLoading && (
            <VideocamOutlined
              fontSize="inherit"
              sx={{ cursor: "pointer" }}
              color={"primary"}
            />
          )}
        </LoadingButton>
      </Flexbox>
    </CustomTooltip>
  );
};

export default CallTwillioPatientButton;
