import { Button, Tab, Tabs, Typography, styled } from "@mui/material";
import { Flexbox } from "../../../styling/NewStyleComponents";
import MemberDetailsLayout from "../MemberDetailsLayout";
import MemberDetailsHeader from "../Header/MemberDetailsHeader";
import { useEffect, useMemo, useState } from "react";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import {
  useGetMemberWithUsernameQuery,
  useGetPatientMetadataQuery
} from "common/services/MemberService";
import MemberRetentionActionsType from "common/types/MemberRetentionActionsType";
import {
  canSeeTechSupportProfileTab,
  canViewPlatformRoles,
  getCancelMemberStatusByRole,
  showsCancellationRequestHistoryButton
} from "common/enums/RolesEnum";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import ContactAttemptDetailsModal from "../../../components/Modal/ContactAttemptDetailsModal";
import SystemInformationBanner from "../../PatientData/SystemInformationBanner";
import CancellationRequestModal from "../../../components/Modal/CancellationRequestModal";
import SurveyModal from "../../PatientData/Survey/SurveyModal";
import { Section } from "../../../styling/StyleComponents";
import { DateTime } from "luxon";
import EditMemberDetailsForm from "./EditMemberDetailsForm";
import { checkIdValid } from "common/helpers/helpers";
import useSanitizedParams from "../../../hooks/useSanitizedParams";
import MemberAgreements from "./MemberAgreements";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import TechSupportTab from "./TechSupportTab";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const MemberDetailsProfileContainer = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const CancellationRequestHistoryButton = styled(Button)`
  text-align: center;
`;

enum TabPanelIndex {
  profile = 0,
  onboarding = 1,
  agreements = 2,
  support = 3
}

const MemberDetailsProfile = ({
  hideReadings = false
}: {
  hideReadings?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useSanitizedParams();
  const { memberId } = params;
  const { tab } = useParams();
  const isValidId = checkIdValid(memberId);

  const { currentRole } = useSelector((state: RootState) => state.auth);

  const { data: patient, error: patientError } = useGetMemberWithUsernameQuery(
    {
      username: memberId,
      linked_entities: [
        MemberLinkedEntitiesEnum.NURSE,
        MemberLinkedEntitiesEnum.PROVIDER,
        MemberLinkedEntitiesEnum.PROVIDER_METADATA,
        MemberLinkedEntitiesEnum.PATIENT_ACCESS_SUMMARY,
        MemberLinkedEntitiesEnum.NPS,
        MemberLinkedEntitiesEnum.RETENTION_ACTIONS,
        MemberLinkedEntitiesEnum.METADATA,
        MemberLinkedEntitiesEnum.MISSING_LEGAL_FORMS_FULL,
        MemberLinkedEntitiesEnum.ACCEPTED_LEGAL_FORMS
      ]
    },
    { skip: memberId === undefined || isValidId === false }
  );

  const {
    data: patientMetadata,
    isSuccess: isSuccessPatientMetadata,
    isFetching: isFetchingPatientMetadata,
    isError: isErrorPatientMetadata
  } = useGetPatientMetadataQuery(
    {
      memberId: memberId
    },
    { skip: isValidId === false }
  );

  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const [selectedRetentionActions, setSelectedRetentionActions] =
    useState<MemberRetentionActionsType[]>(undefined);

  const { status: cancelStatus } = getCancelMemberStatusByRole(
    currentRole,
    patient?.patient?.status
  );

  const [cancellationsRequestModalIsOpen, setCancellationsRequestModalIsOpen] =
    useState<boolean>(false);

  const [surveyModalIsOpen, setSurveyModalIsOpen] = useState<boolean>(false);

  const canSeeTechSupportProfile = canSeeTechSupportProfileTab(currentRole);

  const { bannerTitle, cancelButtonText, cancelModalTitle } = useMemo(() => {
    switch (cancelStatus) {
      case MemberStatusEnum.CANCELED:
        // Retention Specialist & ADMIN
        return {
          bannerTitle: "Cancel Member",
          cancelButtonText: "Cancel Member",
          cancelModalTitle: "Cancellation Reasons"
        };
      // Medical Staff
      case MemberStatusEnum.CANCELLATION_REQUESTED:
        return {
          bannerTitle: "Member Cancellation",
          cancelButtonText: "Request to Cancel",
          cancelModalTitle: "Request to Cancel Reasons"
        };
      default:
        return {
          bannerTitle: "Cancel Member",
          cancelButtonText: "Cancel Member",
          cancelModalTitle: ""
        };
    }
  }, [cancelStatus]);

  const pathname = useMemo(() => {
    const paths = location.pathname.split("/");
    if (paths?.length === 6) paths.pop();

    return paths.join("/");
  }, [location]);

  useEffect(() => {
    switch (tab) {
      case "onboarding":
        setTabIndex(TabPanelIndex.onboarding);
        break;
      case "agreements":
        setTabIndex(TabPanelIndex.agreements);
        break;
      case "support":
        canSeeTechSupportProfile && setTabIndex(TabPanelIndex.support);
        break;
    }
  }, [currentRole, tab]);

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return (
    <MemberDetailsProfileContainer>
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="hidden"
      >
        <MemberDetailsHeader key={`${memberId}-header-profile`} />
        <MemberDetailsLayout
          key={`${memberId}-layout-profile`}
          memberId={memberId}
          hideReadings={hideReadings}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabSelect}
            aria-label="Member Details Profile Tabs"
          >
            <Tab
              label={"Profile info"}
              style={{ fontWeight: "600" }}
              onClick={() => navigate(pathname)}
              value={TabPanelIndex.profile}
            />
            <Tab
              label={"Onboarding"}
              style={{ fontWeight: "600" }}
              onClick={() => navigate(pathname + "/onboarding")}
              value={TabPanelIndex.onboarding}
            />
            <Tab
              label={"Agreements"}
              style={{ fontWeight: "600" }}
              onClick={() => navigate(pathname + "/agreements")}
              value={TabPanelIndex.agreements}
            />
            {canSeeTechSupportProfile && (
              <Tab
                label={"Tech Support"}
                style={{ fontWeight: "600" }}
                onClick={() => navigate(pathname + "/support")}
                value={TabPanelIndex.support}
              />
            )}
          </Tabs>

          {tabIndex === TabPanelIndex.profile && (
            <>
              {isFetchingPatientMetadata && <LoadingFallback delay={50} />}
              {!isFetchingPatientMetadata &&
                (isSuccessPatientMetadata || isErrorPatientMetadata) && (
                  <EditMemberDetailsForm
                    patient={patient}
                    patientError={patientError}
                    appointmentPreferences={{
                      data: patientMetadata?.metadata?.preferences?.appointment,
                      isFetching: isFetchingPatientMetadata,
                      isError: isErrorPatientMetadata,
                      isSuccess: isSuccessPatientMetadata
                    }}
                  />
                )}
              {canViewPlatformRoles(currentRole) && (
                <SystemInformationBanner
                  patient={patient}
                  width="calc(100%-2px)"
                  outerContainerMarginTop="32px"
                />
              )}
            </>
          )}
          {tabIndex === TabPanelIndex.onboarding && (
            <>
              <Section>
                <Typography variant="h3" color="text.primary" mb="45px">
                  Onboarding Information
                </Typography>
                <ul>
                  {patient?.nps && (
                    <li>
                      <b>{"Registered by (NMS):  "}</b>
                      {patient?.nps?.first + " " + patient?.nps?.last}
                    </li>
                  )}
                  {patient?.patient?.created_at && (
                    <li>
                      <b>{"Registered on:  "}</b>
                      {DateTime.fromSQL(patient?.patient?.created_at).toFormat(
                        "MM/dd/yyyy"
                      )}
                    </li>
                  )}
                </ul>
              </Section>

              {/* Putting these tables in modals for now until ENG-3379 is done - otherwise it will look weird with tables of different widths on the same page */}
              {showsCancellationRequestHistoryButton(currentRole) && (
                <>
                  <Flexbox gap="8px">
                    <CancellationRequestHistoryButton
                      variant="outlined"
                      aria-label="showCancellationRequestHistory"
                      onClick={() => {
                        setCancellationsRequestModalIsOpen(true);
                      }}
                    >
                      View Cancellations Request History
                    </CancellationRequestHistoryButton>
                    {patient?.patient_retention_actions && (
                      <CancellationRequestHistoryButton
                        variant="outlined"
                        aria-label="showRetentionContactHistory"
                        onClick={() => {
                          setSelectedRetentionActions(
                            patient?.patient_retention_actions
                          );
                        }}
                      >
                        View Retention Contact History
                      </CancellationRequestHistoryButton>
                    )}
                  </Flexbox>
                  <ContactAttemptDetailsModal
                    retentionActions={selectedRetentionActions}
                    onRequestClose={() =>
                      setSelectedRetentionActions(undefined)
                    }
                    memberId={patient?.patient?.patient_id}
                    key={`contactModal${patient?.patient?.patient_id}`}
                  />

                  <CancellationRequestModal
                    isOpen={cancellationsRequestModalIsOpen}
                    onRequestClose={() =>
                      setCancellationsRequestModalIsOpen(false)
                    }
                  />
                </>
              )}

              {cancelStatus && (
                <>
                  <Typography variant="h6" mt="36px" mb="12px">
                    {bannerTitle}
                  </Typography>
                  <Button
                    sx={{ width: "fit-content" }}
                    variant="contained"
                    color="error"
                    aria-label="makePatientInactive"
                    onClick={() => setSurveyModalIsOpen(true)}
                  >
                    {cancelButtonText}
                  </Button>

                  <SurveyModal
                    isOpen={surveyModalIsOpen}
                    onRequestClose={() => setSurveyModalIsOpen(false)}
                    title={cancelModalTitle}
                    patient={patient}
                  />
                </>
              )}
            </>
          )}
          {tabIndex === TabPanelIndex.agreements && (
            <MemberAgreements patient={patient} />
          )}

          {canSeeTechSupportProfile && tabIndex === TabPanelIndex.support && (
            <TechSupportTab memberId={memberId} />
          )}
        </MemberDetailsLayout>
      </Flexbox>
    </MemberDetailsProfileContainer>
  );
};

export default MemberDetailsProfile;
