import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  styled,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import { useGetTasksQuery } from "common/services/GraphQLPABService";

import { DateTime } from "luxon";
import { Column } from "../../styling/StyleComponents";
import {
  CheckCircle,
  PendingActions,
  SvgIconComponent
} from "@mui/icons-material";
import { success, warning } from "../../styling/colors";
import { grey } from "@mui/material/colors";
import { useMemo, useState } from "react";
import { Task, TaskStatus } from "common/graphql/generated";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { getNameOrUsername, isFalsy } from "common/helpers/helpers";
import { useNavigate } from "react-router-dom";

const Container = styled(Box)`
  margin: 2.5%;
`;

const Circle = styled(Box)`
  border-radius: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled(Box)`
  display: flex;
  gap: 10px;
`;

const Content = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
`;

const CARDS = ({ todoTaskCount, completedTaskCount }) => [
  {
    backgroundColor: warning[100],
    iconColor: warning[600],
    Icon: PendingActions,
    title: "Tasks Remaining Today",
    value: todoTaskCount
  },
  {
    backgroundColor: success[100],
    iconColor: success[700],
    Icon: CheckCircle,
    title: "Tasks Completed Today",
    value: completedTaskCount
  },
  {
    backgroundColor: grey[400],
    title: "Average minutes per task",
    value: "15"
  }
];

interface MaterialIconProps {
  backgroundColor: string;
  iconColor?: string;
  Icon?: SvgIconComponent;
}

const MaterialIcon = ({
  backgroundColor,
  iconColor,
  Icon
}: MaterialIconProps) => {
  return (
    <Circle sx={{ backgroundColor }}>
      {Icon && <Icon htmlColor={iconColor} fontSize="small" />}
    </Circle>
  );
};

interface TaskCardProps extends MaterialIconProps {
  title: string;
  value: string;
}

const TaskCard = ({ title, value, ...iconProps }: TaskCardProps) => {
  return (
    <Card sx={{ padding: 2, width: 250 }}>
      <Row alignItems={"center"}>
        <MaterialIcon {...iconProps} />
        <Typography variant="body1" color="secondary">
          {title}
        </Typography>
      </Row>

      <br />
      <Typography variant="h1" color="secondary">
        {value}
      </Typography>
    </Card>
  );
};

enum TabPanelIndex {
  taskQueue = 0,
  completed = 1
}

const RenderTask = ({ task }: { task: Task }) => {
  const navigate = useNavigate();
  const { data: member } = useGetMemberWithUsernameQuery({
    username: task.patientId
  });

  const typeString = useMemo(() => {
    const splittedString = task?.type?.split("_");
    const splittedStringLowerCase = splittedString.map((item) => {
      return item.charAt(0) + item.slice(1).toLowerCase();
    });

    return splittedStringLowerCase.join(" ");
  }, [task]);

  return (
    <>
      <Row
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={"10px"}
      >
        <Typography width={200} variant="h6">
          {getNameOrUsername(member?.patient)}
        </Typography>
        <Typography width={200} variant="body1">
          {typeString}
        </Typography>
        <Typography variant="body1">
          {DateTime.fromISO(task.eventDate).toFormat("MM/dd/yyyy hh:mm a")}
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate(`/visits/${task.careFlow.visitId}`)}
          disabled={isFalsy(task.careFlow)}
        >
          Start
        </Button>
      </Row>
      <Divider />
    </>
  );
};

const RenderTasks = ({
  tasks,
  status
}: {
  tasks: Task[];
  status: TaskStatus;
}) => {
  const header = useMemo(() => {
    switch (status) {
      case TaskStatus.Queued:
        return "Upcoming Tasks";
      case TaskStatus.Completed:
        return "Completed Tasks";
    }
  }, [status]);

  return (
    <>
      <Typography variant="h5">{header}</Typography>
      <br />

      {tasks?.map((item: Task) => {
        return <RenderTask key={item.id} task={item} />;
      })}

      {tasks?.length === 0 && (
        <Typography variant="body1">There are no tasks to display</Typography>
      )}
    </>
  );
};

const ArcOfDayDashboard = () => {
  const [tabIndex, setTabIndex] = useState<number>(TabPanelIndex.taskQueue);

  const { data: authenticatedUser } = useGetAuthenticatedUser();
  const { data: todoTasks, isLoading: todoLoading } = useGetTasksQuery(
    {
      pageNumber: 0,
      pageSize: 25,
      staffId: authenticatedUser?.user?.user_id,
      status: TaskStatus.Queued
    },
    { skip: authenticatedUser === undefined }
  );
  const { data: completedTasks, isLoading: completedLoading } =
    useGetTasksQuery(
      {
        pageNumber: 0,
        pageSize: 25,
        staffId: authenticatedUser?.user?.user_id,
        status: TaskStatus.Completed
      },
      { skip: authenticatedUser === undefined }
    );

  const isLoading = todoLoading || completedLoading;
  const isSuccess = completedTasks && todoTasks;

  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Container>
      {isLoading && <CircularProgress />}

      {isSuccess && (
        <Row>
          <Column>
            {CARDS({
              todoTaskCount: todoTasks?.totalResults,
              completedTaskCount: completedTasks?.totalResults
            }).map((card) => {
              return <TaskCard {...card} />;
            })}
          </Column>

          <Content>
            <Typography variant="h4">Tasks</Typography>
            <br />
            <Divider style={{ margin: "0px -20px" }} />

            <Tabs value={tabIndex} onChange={handleTabSelect}>
              <Tab
                label={"Task Queue"}
                style={{ fontWeight: "600" }}
                value={TabPanelIndex.taskQueue}
              />
              <Tab
                label={"Completed"}
                style={{ fontWeight: "600" }}
                value={TabPanelIndex.completed}
              />
            </Tabs>

            <br />

            {
              <RenderTasks
                tasks={
                  tabIndex === TabPanelIndex.taskQueue
                    ? todoTasks.items
                    : completedTasks.items
                }
                status={
                  tabIndex === TabPanelIndex.taskQueue
                    ? TaskStatus.Queued
                    : TaskStatus.Completed
                }
              />
            }
          </Content>
        </Row>
      )}
    </Container>
  );
};

export default ArcOfDayDashboard;
