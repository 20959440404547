import { Checkbox, FormControlLabel, styled, Typography } from "@mui/material";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";

interface IProps {
  field: CareFlowFieldType;
}

const MessageIOSTypography = styled(Typography)`
  text-align: left;
  color: white;
`;

const MessageIOSContainer = styled("div")`
  display: flex;
  align-self: end;
  background-color: #007aff;
  border-radius: 10px;
  padding: 10px;
  margin-right: 5%;
  max-width: 80%;
`;

const VisitFieldQuote = ({ field }: IProps) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
      <MessageIOSContainer>
        <MessageIOSTypography variant="body1">
          {field.value}
        </MessageIOSTypography>
      </MessageIOSContainer>
    </div>
  );
};

export default VisitFieldQuote;
