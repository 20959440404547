import { Card, Typography } from "@mui/material";

import { CareFlowSubSectionType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";

import { gray } from "../../../styling/colors";
import { RowContainer } from "../../MemberDetails/StartIntake/StyledComponents";
import VisitContentField from "./VisitContentField";

const VisitContentSubSection = ({
  subSection,
  visit
}: {
  subSection: CareFlowSubSectionType;
  visit: GetVisitResponseType;
}) => {
  return (
    <Card sx={{ padding: 2, margin: 0.5, border: 1, borderColor: `${gray[300]}`, boxShadow: 0 }}>
      <RowContainer sx={{ cursor: "pointer" }} justifyContent={"space-between"}>
        <Typography
          variant="h4"
          sx={{ textDecoration: "underline" }}
          color={gray[900]}
        >
          {subSection?.name}
        </Typography>
      </RowContainer>
      <br />
      {subSection?.fields?.map((item) => (
        <VisitContentField key={item.field_id} visit={visit} field={item} />
      ))}
    </Card>
  );
};

export default VisitContentSubSection;
