enum RouteComponentTypeEnum {
  CREATE_USER = "create-user",
  CARER_PATIENT_LIST = "carer-patient-list",
  PATIENT_LIST = "patient-list",
  INACTIVE_PATIENT_LIST = "inactive-patient-list",
  PATIENT_ACTIVITY_LIST = "patient-activity-list",
  STAFF_LIST = "staff-list",
  STAFF_DETAILS = "staff-details",
  NURSES_SCHEDULES = "nurses-schedules",
  NEW_NURSES_SCHEDULES = "new-nurses-schedules",
  SAME_DAY_TIME_OFF = "same-day-time-off",
  TIME_OFF = "time-off",
  CUSTOM_SEARCH_TABLE = "custom-search-table",
  NURSE_SCHEDULE = "nurse-schedule",
  INTAKES = "intakes",
  PROFILE = "profile",
  REGISTER_MEMBER = "register-patient",
  REQUEST_CONSENTS = "request-consents",
  MIGRATE_PATIENT = "migrate-patient",
  ORDER_DEVICES = "order-devices",
  ORDER_DEVICES_DETAILS = "order-devices-details",
  ALL_RECENT_ORDERS = "all-recent-orders",
  RECENT_ORDERS = "recent-orders",
  HELP = "help",
  ALL_TEAMS = "all-teams",
  MY_TEAM = "my-team",
  SELECTED_TEAM = "selected-team",
  CREATE_TEAM = "create-team",
  REPORT_LIST = "report-list",
  REPORTS = "reports",
  REPORTED_CANCELLATIONS = "reported-cancellations",
  REPORTED_CANCELLATIONS_BY_MEMBER = "reported-cancellations-by-member",
  TASKS = "tasks",
  VISITS = "visits",
  ARC_OF_DAY_DASHBOARD = "arc_of_day_dashboard",

  SCHEDULE_TODO_CONTAINER = "schedule-todo-container",
  NEW_SCHEDULE_TODO_CONTAINER = "new-schedule-todo-container",

  // v2 member details routes
  NEW_MEMBER_DETAILS_OVERVIEW = "new-member-details-overview",
  NEW_MEMBER_DETAILS_READINGS = "new-member-details-readings",
  NEW_MEMBER_DETAILS_CONDITIONS = "new-member-details-conditions",
  NEW_MEMBER_DETAILS_MEDICATIONS = "new-member-details-medications",
  NEW_MEMBER_DETAILS_ENCOUNTERS = "new-member-details-encounters",
  NEW_MEMBER_DETAILS_LABS = "new-member-details-labs",
  NEW_MEMBER_DETAILS_LIFESTYLE = "new-member-details-lifestyle",
  NEW_MEMBER_DETAILS_ORDERS = "new-member-details-orders",
  NEW_MEMBER_DETAILS_VACCINES = "new-member-details-vaccines",
  NEW_MEMBER_DETAILS_PROFILE = "new-member-details-profile",
  NEW_MEMBER_DETAILS_APPOINTMENTS = "new-member-details-appointments",

  NEW_MEMBER_APPOINTMENT = "create-member-appointment",
  EDIT_MEMBER_APPOINTMENT = "edit-member-appointment",
  MANUAL_REASSIGN_APPOINTMENT = "manual-reassign-appointment",
  NEW_MANUAL_REASSIGN_APPOINTMENT = "new-manual-reassign-appointment",

  PATIENT_DETAILS_REDIRECT = "patient-details-redirect",
  PATIENT_CANCELLATION_REDIRECT = "patient-cancellation-redirect",
  MEMBER_DETAILS_REDIRECT = "member-details-redirect",

  MEMBER_DETAILS_START_INTAKE = "member-details-start-intake"
}

export default RouteComponentTypeEnum;
