import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { useState, MouseEvent, useCallback, useEffect } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";
import { canFilterReadingsByDevices } from "common/enums/RolesEnum";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { useGetDevicesQuery } from "common/services/DevicesService";

const dateFilterOptions = [
  { label: "Last 30 Days", value: 30 },
  { label: "Last 60 Days", value: 60 },
  { label: "Last 90 Days", value: 90 },
  { label: "Last 180 Days", value: 180 },
  { label: "Last 365 Days", value: 365 }
];

interface IProps {
  member_id?: string;
  readingType?: DeviceTrendParam;
  deviceIds?: string[];
  handleDataChange: ({
    dateFilter,
    showAverages,
    deviceIds
  }: {
    dateFilter: number;
    showAverages: boolean;
    deviceIds: string[];
  }) => void;
}

const ChartControls = ({
  member_id,
  readingType,
  handleDataChange,
  deviceIds
}: IProps) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [showAverages, setShowAverages] = useState<boolean>(true);
  const [dateFilter, setDateFilter] = useState<number>(30);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateFilterChange = useCallback(
    (value) => {
      setDateFilter(value);
      handleClose();
    },
    [setDateFilter, handleClose]
  );

  useEffect(() => {
    handleDataChange &&
      handleDataChange({
        dateFilter,
        showAverages,
        deviceIds: selectedDeviceIds
      });
  }, [dateFilter, showAverages, selectedDeviceIds]);

  const { data: memberDevices } = useGetDevicesQuery(
    {
      memberId: member_id
    },
    { skip: member_id === undefined }
  );

  return (
    <Flexbox justifyContent="space-between" alignItems="center">
      <Flexbox alignItems="center">
        <Checkbox
          checked={showAverages}
          onChange={() => setShowAverages(!showAverages)}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography variant="body1">Show average values</Typography>

        {canFilterReadingsByDevices(currentRole) &&
          deviceIds &&
          memberDevices && (
            <TextField
              value={selectedDeviceIds}
              label="Devices"
              select
              SelectProps={{
                multiple: true,
                onChange: (event) => {
                  const values = event.target.value as string[];
                  if (values.includes("All")) setSelectedDeviceIds([]);
                  else setSelectedDeviceIds(values);
                }
              }}
              sx={{ marginLeft: "10px", width: "500px" }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              {deviceIds?.map((serialNumber) => {
                const device = memberDevices.find(
                  (item) => item.device_id === serialNumber
                );
                return (
                  <MenuItem key={serialNumber} value={serialNumber}>
                    {device?.extra?.vendor_device_id ?? "Legacy"}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
      </Flexbox>

      <Flexbox alignItems="center">
        <Typography variant="body1">Show</Typography>
        <Button
          aria-controls={open ? `${readingType}-date-range` : undefined}
          aria-haspopup="true"
          variant="text"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDown />}
          sx={{ textTransform: "none", width: "150px" }}
        >
          Last {dateFilter} days
        </Button>
        <Menu
          id={`${readingType}-date-range`}
          MenuListProps={{
            "aria-labelledby": `${readingType}-date-range`
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {dateFilterOptions.map((option) => {
            return (
              <MenuItem
                key={`${readingType}-date-range-label-${option.value}`}
                onClick={() => handleDateFilterChange(option.value)}
                disableRipple
              >
                {option.label}
              </MenuItem>
            );
          })}
        </Menu>
      </Flexbox>
    </Flexbox>
  );
};

export default ChartControls;
