import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

import { DateTime } from "luxon";

import { RootState, useAppDispatch } from "common/redux";
import { setSelectedSection, stopTicking } from "common/redux/VisitsSlice";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { getNameOrUsername, ONE_MINUTE } from "common/helpers/helpers";
import { VisitStateType } from "common/types/Visits/CareFlowStateType";

import { Modal } from "../../components/Modal";
import VisitContentSubSection from "./ui/VisitContentSubSection";
import { RowContainer } from "../MemberDetails/StartIntake/StyledComponents";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  CareFlowFieldType,
  CareFlowSubSectionType
} from "common/types/Visits/CareFlowResponseType";
import CareFlowSectionsEnum from "common/enums/Calendaring/Visits/CareFlowSectionsEnum";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import FeatureFlags from "common/config/FeatureFlags";

const Container = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-self: stretch;
`;

const Spacing = styled("div")`
  flex: 1;
`;

const ModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ContentContainer = styled(Box)`
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-y: scroll;
`;

const CHECKBOX_CONNECTED = "Connected";
const PLEASE_REVIEW = "Please Review";

const VisitContent = ({
  visit,
  syncCareFlowToAPI,
  isUpdateVisitSuccess,
  isUpdateVisitLoading
}: {
  visit: GetVisitResponseType;
  syncCareFlowToAPI: (status?: VisitStateType) => Promise<void>;
  isUpdateVisitSuccess: boolean;
  isUpdateVisitLoading: boolean;
}) => {
  const navigate = useNavigate();

  const { selectedSection, elapsedTime, selectedSubSection, answers } =
    useSelector((state: RootState) => state.visits);

  const [completePressed, setCompletePressed] = useState<boolean>();
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [showEndCallModal, setShowEndCallModal] = useState<boolean>(false);

  const memberId = visit?.patient_id;
  const { data: member } = useGetMemberWithUsernameQuery(
    {
      username: memberId
    },
    { skip: memberId === undefined }
  );

  const dispatch = useAppDispatch();

  const currentSectionIndex = visit?.care_flow?.sections?.findIndex(
    (item) => item.section_id === selectedSection
  );

  const currentSection = visit?.care_flow?.sections[currentSectionIndex];
  const currentSubSections =
    visit?.care_flow?.sections[currentSectionIndex]?.subsections;
  const currentSubSectionIndex = currentSubSections?.findIndex(
    (item) => item.section_id === selectedSubSection
  );

  const length = visit?.care_flow?.sections?.length;
  const isLastSection = currentSectionIndex >= length - 1;

  const isLastSubsection =
    currentSubSectionIndex >= currentSubSections?.length - 1;

  const isCallSection = currentSection?.section_id == CareFlowSectionsEnum.CALL;

  const section = useMemo(() => {
    return visit?.care_flow?.sections?.find(
      ({ section_id }) => section_id === selectedSection
    );
  }, [selectedSection]);

  const elapsedTotalDateTime = useMemo(() => {
    const totalSeconds =
      elapsedTime?.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue?.actual_time_seconds || 0,
        0
      ) ?? 0;

    return DateTime.fromSeconds(totalSeconds);
  }, [elapsedTime]);

  const navNextSection = () => {
    if (isLastSection) return;

    const newSection = visit?.care_flow?.sections[currentSectionIndex + 1];
    dispatch(
      setSelectedSection({
        section_id: newSection.section_id,
        subsection_id: newSection.subsections[0].section_id
      })
    );
  };

  // Used by End Call to skip validation in normal goNextSection
  const forceGoNextSection = () => {
    setShowEndCallModal(false);
    navNextSection();
  };

  const goNextSection = () => {
    if (isLastSection) return;

    let firstSubsection: CareFlowSubSectionType = null;
    const unansweredFields: CareFlowFieldType[] = [];
    const currentSection = visit?.care_flow?.sections[currentSectionIndex];
    currentSection.subsections.forEach((subsection) => {
      subsection.fields.forEach((field) => {
        if (field.is_required) {
          const isAnswered = answers[field.field_id] !== undefined;
          if (!isAnswered) {
            unansweredFields.push(field);
            if (!firstSubsection) firstSubsection = subsection;
          }
        }
      });
    });

    if (unansweredFields.length > 0 && FeatureFlags.CARE_FLOWS_REQUIRED_FIELD_CHECK) {
      Alert_show({
        dispatch,
        title: PLEASE_REVIEW,
        hideCloseIcon: true,
        content: (
          <>
            <Typography variant="h6" color={"secondary"} textAlign={"center"}>
              The following fields are required to proceed to the next section:
            </Typography>
            {unansweredFields.map((field) => {
              return (
                <Typography
                  key={field.field_id}
                  variant="body1"
                  textAlign={"center"}
                >
                  - {field.name ? field.name : field.field_id}
                </Typography>
              );
            })}
          </>
        ),
        buttons: [
          {
            text: "Review",
            onPress: () => {
              Alert_close({ dispatch, title: PLEASE_REVIEW });
              dispatch(
                setSelectedSection({
                  section_id: currentSection.section_id,
                  subsection_id: firstSubsection.section_id
                })
              );
            }
          }
        ]
      });
      return;
    }

    navNextSection();
  };

  const validateSubSection = async (currentSubSection) => {
    for (const field of currentSubSection.fields) {
      // custom check for not connected
      if (
        field.field_id === CareFlowFieldIdsEnum.CALL_CONNECTION &&
        answers[field.field_id] !== CHECKBOX_CONNECTED &&
        FeatureFlags.CARE_FLOWS_REQUIRED_FIELD_CHECK
      ) {
        Alert_show({
          dispatch,
          size: "small",
          hideCloseIcon: true,
          title: PLEASE_REVIEW,
          content: (
            <>
              <Typography variant="h6" color={"secondary"} textAlign={"center"}>
                Unable to continue without connecting to member.
              </Typography>
            </>
          ),
          buttons: [
            {
              text: "Review",
              onPress: () => {
                Alert_close({ dispatch, title: PLEASE_REVIEW });
              }
            }
          ]
        });
        return false;
      }
    }
    return true;
  };

  const goNextSubsection = async () => {
    if (isLastSubsection) return;

    const subSections =
      visit?.care_flow?.sections[currentSectionIndex].subsections;

    const currentSubSectionIndex = subSections.findIndex(
      (item) => selectedSubSection === item.section_id
    );
    const isValid = await validateSubSection(
      subSections[currentSubSectionIndex]
    );

    if (isValid) {
      dispatch(
        setSelectedSection({
          section_id:
            visit?.care_flow?.sections[currentSectionIndex].section_id,
          subsection_id: subSections[currentSubSectionIndex + 1].section_id
        })
      );
    }
  };

  const handleSaveAndNextSection = () => {
    syncCareFlowToAPI();
    goNextSection();
  };

  const handleSaveAndNextSubsection = () => {
    syncCareFlowToAPI();
    goNextSubsection();
  };

  const handleSaveAndNext = () => {
    if (isLastSection && isLastSubsection) return;

    if (isLastSubsection) {
      handleSaveAndNextSection();
    } else {
      handleSaveAndNextSubsection();
    }
  };

  const handleComplete = async () => {
    dispatch(stopTicking());
    setCompletePressed(true);
    await syncCareFlowToAPI({
      status: "COMPLETED",
      total_time: Math.round(elapsedTotalDateTime.toMillis() / (60 * 1000))
    });

    setTimeout(() => setCompletePressed(false), 1000);
  };

  const handleEndCall = async () => {
    setShowEndCallModal(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      syncCareFlowToAPI();
    }, ONE_MINUTE * 1000);

    return () => {
      syncCareFlowToAPI();
      clearInterval(interval);
    };
  }, []);

  const goToDashboardHandler = () => {
    navigate("/");
  };

  const completeVisitSuccess = isUpdateVisitSuccess && completePressed;
  const completeVisitLoading = isUpdateVisitLoading && completePressed;

  const elapsedTotalTimeModalString = useMemo(() => {
    return elapsedTotalDateTime.toFormat("mm:ss");
  }, [completeVisitSuccess]);

  useEffect(() => {
    if (completeVisitSuccess) setShowCompleteModal(true);
  }, [completeVisitSuccess]);

  useEffect(() => {
    if (section) {
      setSelectedSection({
        section_id: section.section_id,
        subsection_id: section.subsections[0].section_id
      });
    }

    return () => {
      if (section) syncCareFlowToAPI();
    };
  }, [section]);

  const { height } = useWindowDimensions();

  return (
    <Container>
      <ContentContainer height={height - 350}>
        <VisitContentSubSection
          subSection={section?.subsections[currentSubSectionIndex]}
          visit={visit}
        />
      </ContentContainer>
      <br />
      <RowContainer gap={"10px"} justifyContent={"space-between"}>
        {isLastSection && isLastSubsection ? (
          <LoadingButton
            onClick={handleComplete}
            loading={completeVisitLoading}
            variant="contained"
          >
            Complete
          </LoadingButton>
        ) : (
          <Button onClick={handleSaveAndNext} variant="contained">
            Next
          </Button>
        )}
        {isCallSection && (
          <Button onClick={handleEndCall} variant="contained" color="error">
            End Call
          </Button>
        )}
      </RowContainer>

      <Modal
        isOpen={showCompleteModal}
        contentLabel="Complete CareFlow Modal"
        modalHeight="300px"
        modalWidth="400px"
      >
        <ModalContainer>
          <Typography variant="h6" color={"secondary"}>
            You have successfully completed your call with
          </Typography>
          <Typography variant="h4">
            {getNameOrUsername(member?.patient)}
          </Typography>
          <Typography variant="h6" color={"secondary"}>
            Total time spent: {elapsedTotalTimeModalString}
          </Typography>
          <Button variant="contained" onClick={goToDashboardHandler}>
            Go to Dashboard
          </Button>
        </ModalContainer>
      </Modal>
      <Modal
        isOpen={showEndCallModal}
        contentLabel="CareFlow EndCall Modal"
        modalHeight="300px"
        modalWidth="400px"
      >
        <ModalContainer>
          <Typography variant="h6" color={"secondary"}>
            Are you sure you want to leave this call?
          </Typography>
          <Box
            display={"flex"}
            flexDirection={"row"}
            minWidth={"80%"}
            marginTop={"25%"}
            justifyContent={"space-evenly"}
          >
            <Button
              variant="outlined"
              onClick={() => setShowEndCallModal(false)}
              sx={{ minWidth: "120px" }}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={forceGoNextSection}
              sx={{ minWidth: "120px" }}
            >
              Yes
            </Button>
          </Box>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default VisitContent;
