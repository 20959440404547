import { useEffect } from "react";
import { checkIdValid } from "common/helpers/helpers";
import { Typography } from "@mui/material";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import MemberDetailsReadings from "./Readings/MemberDetailsReadings";
import EnvVars from "common/config/EnvVars";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { canSeeEncounters, canSeeReadings } from "common/enums/RolesEnum";
import FeatureFlags from "common/config/FeatureFlags";

export default function PatientDetailsRedirect() {
  const params = useSanitizedParams();
  const { memberId } = params;

  const { currentRole } = useSelector((state: RootState) => state.auth);

  const isValidId = checkIdValid(memberId);

  useEffect(() => {
    if (memberId) {
      if (!FeatureFlags.CARE_FLOWS) {
        window.location.href = `/members/memberId/${memberId}/overview`;
      } else {
        window.location.href = `/members/memberId/${memberId}/appointments`;
      }
    }
  }, [memberId, currentRole]);

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return <MemberDetailsReadings />;
}
